import React, {useCallback} from "react";
import { IoInformationCircle} from "react-icons/io5";
import {Button} from "../../../components/generic/Buttons";
import {
    IPhysicianAppointmentLocation,
    IPhysicianAppointmentPatient,
    IPhysicianAppointmentPractice, IPhysicianAppointmentProvider, LanguageCodeDescription,

} from "../../../models";
import {Tooltip} from "react-tooltip";
import {formatPhoneNumber} from "../../../shared/Utils";

interface IPhysicianAppointmentInfoComponentProps {
    value: {
        appointmentId: string;
        patient: IPhysicianAppointmentPatient;
        practice: IPhysicianAppointmentPractice;
        location: IPhysicianAppointmentLocation;
        provider: IPhysicianAppointmentProvider;
    },
}

const PhysicianAppointmentInfoComponent = (props: IPhysicianAppointmentInfoComponentProps) => {

    const {value} = props;
    const {appointmentId, location, provider, practice, patient} = value;


    const renderToolTipForDetails = useCallback(() => {
        return <Tooltip style={{borderRadius: 5, zIndex: 9999}}
                        id={`${appointmentId}-physician-appointment-info-tooltip`}>
            <div>
                Location: {location?.name}
            </div>
            <div>
                Practice: {practice?.name}
            </div>
            <div>
                Location Phone Number: {location?.phone} ( Phone {location.isPhoneVerified ? "Verified" : "Not Verified"} )
            </div>
            <div>
                Physician: Dr. {provider ? provider?.firstName : ''} {provider ? provider?.lastName : ''}
            </div>
            <div>
                Patient Phone Number: {formatPhoneNumber(patient?.contactPhoneNumber)}
            </div>
            <div>
                Patient Preferred Language: {LanguageCodeDescription[patient?.preferredLanguage] || patient?.preferredLanguage || 'Not Available'}
            </div>
        </Tooltip>
    }, [appointmentId, patient, location, provider]);

    return <>
        <Button
            data-tooltip-id={`${appointmentId}-physician-appointment-info-tooltip`}
            className={`rounded-xl !ml-2 px-[8px] py-[8px] !h-8 text-sm border-sjOrange text-sjOrange`}>
            <IoInformationCircle/>
        </Button>
        {renderToolTipForDetails()}
    </>
}

export default PhysicianAppointmentInfoComponent;
