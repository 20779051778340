import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { StateParams } from '../../store/reducers'
import PaginationV2 from "../../components/generic/PaginationV2";
import PhysicianAppointmentListComponent from "./PhysicianAppointmentListComponent";
import {
    fetchPhysicianAppointments,
    patientCommentsSidebarInActive
} from "../../store/actions/care-coordinator/patients.action";
import { Button } from "../../components/generic/Buttons";
import PhysicianAppointmentFormComponent from "./PhysicianAppointmentFormComponent";
import { fetchPracticeMasterList } from "../../store/actions/care-coordinator/master-data.action";
import { DateTime } from "luxon";
import PhysicianAppointmentsFiltersComponent from "./PhysicianAppointmentsFiltersComponent";
import { IoFilter } from 'react-icons/io5';
import { useFlags } from 'flagsmith/react';
import PhysicianAppointmentAdvanceFilters from './PhysicianAppointmentAdvanceFilters';
import {IoMdRefresh} from "react-icons/io";
import RefreshComponent from "../../components/generic/RefreshComponent";

const PhysicianAppointmentsScreen = () => {

    const dispatch = useDispatch();
    const {
        physicianAppointmentList,
        physicianAppointmentListInProgress,
        physicianAppointmentListSuccess,
        physicianAppointmentListCount,
        PatientCommentsSideBarActive,
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const flags = useFlags(['physician_appointments_advanced_filters'])
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNumber, setPageNumber] = useState(Number(searchParams.get("page")) || 1);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [filterStartDate, setFilterStartDate] = useState<string | undefined>(undefined);
    const [filterEndDate, setFilterEndDate] = useState<string | undefined>(undefined);
    const [practiceId, setPracticeId] = useState<string | undefined>(undefined);
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [calledPatient, setCalledPatient] = useState<string | undefined>(undefined);
    const [callResponse, setCallResponse] = useState<string | undefined>(undefined);
    const [smsStatus, setSmsStatus] = useState<string | undefined>(undefined);
    const [screeningStatus, setScreeningStatus] = useState<string | undefined>(undefined);
    const [preferredLanguage, setPreferredLanguage] = useState<string | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const isAdvancedFilterEnabled = flags.physician_appointments_advanced_filters.enabled


    useEffect(() => {
        if (searchParams) {
            if (searchParams.get("page")) {
                setPageNumber(Number(searchParams.get("page")));
            } else {
                setPageNumber(1);
            }
        }
    }, [searchParams]);

    useEffect(() => {
        dispatch(fetchPracticeMasterList());
         dispatch(patientCommentsSidebarInActive());
    }, [dispatch]);

    useEffect(() => {
        getPhysicianAppointments();
    }, [pageNumber, recordsPerPage, searchText, practiceId, filterStartDate, filterEndDate]);

    const getPhysicianAppointments = useCallback(() => {
        if (pageNumber || recordsPerPage || searchText || !calledPatient || !callResponse || !smsStatus || !screeningStatus || !preferredLanguage) {
            dispatch(fetchPhysicianAppointments({
                pageNumber,
                recordsPerPage,
                searchText,
                startDate: filterStartDate,
                endDate: filterEndDate,
                practiceId: practiceId,
                calledPatient,
                callResponse,
                smsStatus,
                screeningStatus,
                preferredLanguage
            }));
        }
    }, [pageNumber, recordsPerPage, searchText, filterStartDate, filterEndDate, dispatch, practiceId, calledPatient, callResponse, smsStatus, screeningStatus, preferredLanguage]);

    const onFiltersApply = useCallback(() => {
        setOpenFilterModal(false)
        setPageNumber(1);
        setFilterCount(
            (calledPatient ? 1 : 0) +
            (callResponse ? 1 : 0) +
            (smsStatus ? 1 : 0) +
            (screeningStatus ? 1 : 0) +
            (preferredLanguage ? 1 : 0)
        );
        getPhysicianAppointments();
    }, [getPhysicianAppointments]);

    const openFormDrawer = useCallback(() => {
        setShowForm(true);
    }, []);

    const closeFormDrawer = useCallback((refreshList: boolean) => {
        setShowForm(false);
        if (refreshList) {
            getPhysicianAppointments();
        }
    }, [getPhysicianAppointments]);


    const renderPhysicianAppointments = useCallback(() => {
        return <>
            <div className={showForm ? "foreground" : ""} />
            <div className={showForm ? "uneditable" : ""}>
                <div className="flex flex-row justify-between items-center flex-wrap gap-2">
                    <div className='flex'>
                        <h1 className="text-xl"> SMS Appointments </h1>
                    </div>
                    <div className='flex flex-row gap-x-2 items-center flex-wrap gap-2'>
                        <PhysicianAppointmentsFiltersComponent
                            searchText={searchText}
                            setSearchText={setSearchText}
                            setCurrentPage={setPageNumber}
                            setPracticeId={setPracticeId}
                            onFilterChange={(startDate, endDate) => {
                                setFilterStartDate(startDate);
                                setFilterEndDate(endDate);
                            }}
                        />
                        <RefreshComponent onRefresh={getPhysicianAppointments}/>
                        <Button className={"!bg-sjOrange !text-white !ml-2"}
                            onClick={openFormDrawer}>
                            Add Appointment +
                        </Button>
                        {isAdvancedFilterEnabled && <button className="btn btn-icon relative"
                            onClick={() => setOpenFilterModal(true)}
                            aria-label="Open Advanced Filters">
                            <IoFilter className="h-7 w-7 cursor-pointer" color="#575F6E"
                            />
                            {filterCount > 0 && (
                                <span className="absolute bottom-3 left-3 flex h-5 w-5 items-center justify-center rounded-full bg-orange-500 text-white text-xs">
                                    {filterCount}
                                </span>
                            )}
                        </button>}
                    </div>
                </div>
                <div className="mt-4">
                    <PhysicianAppointmentListComponent
                        searchInProgress={physicianAppointmentListInProgress}
                        physicianAppointmentList={physicianAppointmentList}
                        refreshPhysicianAppointmentList={getPhysicianAppointments}
                    />
                    {(physicianAppointmentListSuccess && physicianAppointmentList) &&
                        <PaginationV2
                            totalRows={physicianAppointmentListCount || 0}
                            rowsPerPage={recordsPerPage}
                            recordsPerPageHandler={setRecordsPerPage}
                            currentPage={pageNumber}
                            currentPageHandler={setPageNumber}
                            showPages={false}
                        />
                    }
                </div>
            </div>
        </>;
    }, [
        openFormDrawer, searchText,
        showForm, physicianAppointmentList,
        physicianAppointmentListInProgress,
        physicianAppointmentListCount, physicianAppointmentListSuccess,
        recordsPerPage, pageNumber, getPhysicianAppointments,
        setRecordsPerPage, setPageNumber
    ]);

    const renderRightSidebar = useCallback(() => {
        return <>
            {showForm &&
                <div className={"absolute top-0 right-0 w-1/2 bg-white z-[2000]"}>
                    <PhysicianAppointmentFormComponent onClose={closeFormDrawer} />
                </div>
            }
        </>
    }, [showForm, closeFormDrawer]);

    return (
        <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
            {renderRightSidebar()}
            {renderPhysicianAppointments()}
            <div className={PatientCommentsSideBarActive ? "foreground" : 'uneditable'} />
            {openFilterModal && <PhysicianAppointmentAdvanceFilters
                isOpen={openFilterModal}
                setOpenFilterModal={setOpenFilterModal}
                onFiltersApply={onFiltersApply}
                calledPatient={calledPatient}
                setCalledPatient={setCalledPatient}
                callResponse={callResponse}
                setCallResponse={setCallResponse}
                smsStatus={smsStatus}
                setSmsStatus={setSmsStatus}
                screeningStatus={screeningStatus}
                setScreeningStatus={setScreeningStatus}
                preferredLanguage={preferredLanguage}
                setPreferredLanguage={setPreferredLanguage}
            />}
        </div>
    )
}

export default PhysicianAppointmentsScreen;
