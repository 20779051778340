import React from "react";
import { CommentsResponse } from "../../models";
import {getSystemTimeZone} from "../../shared/DateUtils";
import ProfileIcon from "../../assets/images/common/profile.svg";
import { roleTextFormatter } from "../../shared/Utils";
import { DateTime } from "luxon";


interface CommentProps {
    commentObject: CommentsResponse;
}

const CommentComponent: React.FC<CommentProps> = (props) => {

    const { commentObject } = props;

    return (
        <div className="border border-gray-200 rounded-md p-4 mb-6">
            <div className="flex justify-between mb-4">
                <div className="flex items-start gap-4">
                    <div>
                        <img src={ProfileIcon} alt="Profile Icon" className="w-10 h-10" />
                    </div>
                    <div>
                        <div className="font-medium text-sm text-black">{commentObject.user?.firstName} {commentObject.user?.lastName}</div>
                        <div className="text-sjGray text-xs">{commentObject?.user?.roles.length > 0 && roleTextFormatter(
                            commentObject.user?.roles.map(role => role).join(', ')
                        )}</div>
                    </div>
                </div>

                <div className="text-sjGray italic text-xs">
                    <div className="text-sjGray italic text-xs">
                        {commentObject?.createdAt &&
                            DateTime.fromISO(commentObject?.createdAt).setZone('local').toFormat("MM/dd/yyyy, h:mm a") + ' ' + getSystemTimeZone()
                        }
                    </div>
                </div>

            </div>
            <div className="break-all overflow-hidden overflow-ellipsis whitespace-pre-wrap"
            >
                {commentObject?.comment}
            </div>

        </div>
    );
}

export default CommentComponent;