import { AppointmentStatus, IAppointmentWithProspect, Roles, SessionTypes } from "../models";
import { DateTime } from "luxon";

export function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export function normalizeUnderScoredString(str: string | null, capitalizeFirstLetterOfWords = true) {
    if (!str) return str;
    const normalizedStr = str.replace(/_/g, " ");
    return capitalizeFirstLetterOfWords ? normalizedStr.replace(/\b\w/g, (char) => char.toUpperCase()) : normalizedStr;
}

export function isPhoneNumberValid(phoneNumber: string) {
    const US_FORMATTED_NUMBER_REGEX = /\+1\d{10}$/;
    const IN_FORMATTED_NUMBER_REGEX = /\+91\d{10}$/;
    if (phoneNumber && (phoneNumber?.match(US_FORMATTED_NUMBER_REGEX) || phoneNumber?.match(IN_FORMATTED_NUMBER_REGEX))) {
        return true;
    } else {
        return false;
    }
}

export function formatPhoneNumber(value: string | null | undefined): string {
    if (!value) return '';

    if (value?.startsWith("+91")) {
        // Format for India: +91 99999 99999
        return value.replace(/(\+91)(\d{5})(\d{5})/, "$1 $2 $3");
    }

    if (value?.startsWith("+1")) {
        // Format for US: +1 (999) 999-9999
        return value.replace(/(\+1)(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
    }

    // Return the original value for other cases
    return value;
}

const STARTABLE_STATUSES = [AppointmentStatus.started, AppointmentStatus.inProgress, AppointmentStatus.inProgressUnderscored];

function isSessionReadyToStart(firstNonCancelledIntakeSession: IAppointmentWithProspect) {
    const isScheduledIntakeSession = firstNonCancelledIntakeSession
        && (
            [AppointmentStatus.scheduled, AppointmentStatus.rescheduled].includes(firstNonCancelledIntakeSession.status)
            && DateTime.fromISO(firstNonCancelledIntakeSession.scheduledDate) <= DateTime.now()
        );

    return firstNonCancelledIntakeSession
        && (STARTABLE_STATUSES.includes(firstNonCancelledIntakeSession.status) || isScheduledIntakeSession)
}

export const deduceActionStatus = (appointment: IAppointmentWithProspect): AppointmentStatus => {
    const sessionIsReadyToStart = isSessionReadyToStart(appointment)
    if (sessionIsReadyToStart && [AppointmentStatus.scheduled, AppointmentStatus.rescheduled].includes(appointment.status)) {
        return AppointmentStatus.ready
    }
    if (sessionIsReadyToStart && [AppointmentStatus.started, AppointmentStatus.inProgress, AppointmentStatus.inProgressUnderscored].includes(appointment.status)) {
        return AppointmentStatus.rejoin
    }
    if (AppointmentStatus.noShow === appointment.status) {
        return AppointmentStatus.noShow
    }
    return appointment.status in AppointmentStatus ? AppointmentStatus[appointment.status] : AppointmentStatus.unknown
}

export const RoutePaths = {
    app: { root: "/" },
    home: {
        root: "home",
        physician: "physician",
        nonPhysician: "non-physician"
    },
    collaborativeCare: {
        compliance: {
            root: "/compliance",
            screeningPendingPatients: "screening-pending-patients",
            journey: ":patientId/patient-journey",
            clinicalNotesPendingList: "clinical-notes-pending-list"
        },
        prospects: {
            root: "/prospects",
            summary: ":prospectId/summary",
            startIntake: ":prospectId/start-intake",
            viewIntake: ":prospectId/view-intake",
            startIntakeV2: ":prospectId/start-intake-v2",
            scheduleIntakeAppointment: ":prospectId/schedule-intake",
            editIntakeAppointment: ":prospectId/appointments/:appointmentId/edit-intake"
        },
        patients: {
            root: "patients",
            summary: ":patientId/summary",
            createAppointment: "create",
            treatment: ":patientProspectId/treatment",
            progressNotes: ":patientProspectId/progress-notes",
            catchupNotes: ":patientProspectId/catchup-notes",
            individualPsychiatristNotes: ":patientProspectId/psychiatrist-notes",
            scheduleIntakeAppointment: ":patientId/schedule-intake",
            editIntakeAppointment: ":patientId/appointments/:appointmentId/edit-intake",
            journey: {
                root: ":patientId/journey",
                scheduleBackDatedAppointment: "schedule-backdated-appointment",
            },
            dischargeSummary: ":patientId/discharge-summary",
            patientDetails: ":patientId/patient-details",
            safetyPlan: ":patientProspectId/safety-plan",
            screeners: ":sourcePatientId/screeners"
        },
        appointments: {
            root: "appointments",
            create: "create",
        },
        dischargedPatients: {
            root: "discharged-patients",
            journey: ":patientId/journey",
            dischargeSummary: ":patientId/discharge-summary",
        }
    },
    physicianCare: {
        dashboard: "/physician/dashboard",
        forgotPassword: "/forgotpassword",
        otpScreen: "/otpScreen",
        resetVerification: "/resetVerification",
        changeDefaultPassword: "/changeDefaultPassword",
        changePassword: "/changePassword",
        prospects: "/physician/prospects",
        patients: "/physician/patients",
        patientJourney: "/physician/patients/:patientId/journey",
        prospectProfile: "/physician/prospects/:patientId",

        patientDetails: "/physician/patients/:patientId/patient-details",
        treatment: "/physician/patients/:patientProspectId/treatment",
        progressNotes: "/physician/patients/:patientProspectId/progress-notes",
        catchupNotes: "/physician/patients/:patientProspectId/catchup-notes",
        individualPsychiatristNotes: "/physician/patients/:patientProspectId/psychiatrist-notes",
        safetyPlan: "/physician/patients/:patientProspectId/safety-plan",
        screeners: "/physician/patients/:sourcePatientId/screeners",

        qrCode: "/qrCode",
        providerProfile: "/providerProfile",
        referPatient: "/referScreen/:patientId",
        bhScreening: "/bhScreening",
        debug: "debug",
        zoom: "zoom",
        zoomJoin: "join",
        zoomCall: "call",

        locations: {
            root: "/locations",
            create: "create",
            edit: "edit/:locationId"
        },
        soap: {
            root: "/notes",
        },
        users: {
            root: "/users",
            create: "create",
            edit: "edit/:userId"
        },
        dischargedPatients: {
            root: "physician/discharged-patients",
            journey: ":patientId/journey",
            dischargeSummary: ":patientId/discharge-summary",
        }
    },
    careCoordinator: {
        compliance: {
            root: "/coordinator/compliance/sw-list",
            clinicalNotesPendingList: ":therapistId/clinical-notes-pending-list",
            screenerPendingList: ":therapistId/screener-pending-list",
            // journey: ":patientId/patient-journey",
        },
        potentialPatients: {
            root: "/coordinator/potential-patients",
            patientDetails: {
                root: ":patientId",
                patientInformation: "patient-information",
                screenerSummary: "screener-summary",
                insurance: "insurance",
                comments: "comments"
            },
        },
        referredPatient: {
            root: "/coordinator/referred-patients",
            summary: {
                root: ":prospectId/summary",
                appointments: ":prospectId/appointment"
            }
        },
        ccReferredPatients: {
            root: "/coordinator/cc-referred-patients",
            // summary: {
            //     root: ":prospectId/summary",
            //     appointments: ":prospectId/appointment"
            // }
            patientDetails: {
                root: ":prospectId",
                patientInformation: "patient-information",
                screenerSummary: "summary",
                insurance: "insurance",
                appointments: "appointments",
                comments: "comments"
            },
        },
        cocmPatient: {
            root: "/coordinator/patients",
            summary: ":patientId/summary",
            journey: ":patientId/journey"
        },
        patientMaster: {
            root: "/coordinator/patient-master",
        },
        sendScreenerLink: "/coordinator/send-screener-link",
        physicianAppointments: "/coordinator/physician-appointments",
        smsDashboard: "/coordinator/sms-dashboard",
    },
    medicalAssistance: {
        prospects: "/ma/prospects",
        maProfile: "/maProfile",
        prospectProfile: "/ma/prospects/:patientId",
    },
    notFound: "not-found"
}

interface RouteHandler {
    handleRoute(sessionType: SessionTypes, notesId: string, patientProspectId: string): string;
}

class PhysicianRouteHandler implements RouteHandler {
    handleRoute(sessionType: SessionTypes, notesId: string, patientProspectId: string): string {
        switch (sessionType) {
            case SessionTypes.Intake:
                return `/physician/patients/${patientProspectId}/patient-details`
            case SessionTypes.SafetyPlan:
                return `/physician/patients/${patientProspectId}/safety-plan?notesId=${notesId}`
            case SessionTypes.FollowUp:
                return `/physician/patients/${patientProspectId}/progress-notes?notesId=${notesId}`
            case SessionTypes.TreatmentPlan:
                return `/physician/patients/${patientProspectId}/treatment?notesId=${notesId}`
            case SessionTypes.Catchup:
                return `/physician/patients/${patientProspectId}/catchup-notes?notesId=${notesId}`
            case SessionTypes.PsychiatristNotes:
                return `/physician/patients/${patientProspectId}/psychiatrist-notes?notesId=${notesId}`
            default:
                return `/physician/patients/${patientProspectId}/patient-details`
        }
    }
}

class NonPhysicianRouteHandler implements RouteHandler {
    handleRoute(sessionType: SessionTypes, notesId: string, patientProspectId: string): string {
        switch (sessionType) {
            case SessionTypes.Intake:
                return `/patients/${patientProspectId}/patient-details`
            case SessionTypes.SafetyPlan:
                return `/patients/${patientProspectId}/safety-plan?notesId=${notesId}`
            case SessionTypes.FollowUp:
                return `/patients/${patientProspectId}/progress-notes?notesId=${notesId}`
            case SessionTypes.TreatmentPlan:
                return `/patients/${patientProspectId}/treatment?notesId=${notesId}`
            case SessionTypes.Catchup:
                return `/patients/${patientProspectId}/catchup-notes?notesId=${notesId}`
            case SessionTypes.PsychiatristNotes:
                return `/patients/${patientProspectId}/psychiatrist-notes?notesId=${notesId}`
            default:
                return `/patients/${patientProspectId}/patient-details`
        }
    }
}

export const handleJourneyRoutes = (sessionType: SessionTypes, notesId: string, patientProspectId: string, role: string | undefined): string => {
    let routeHandler: RouteHandler;
    switch (role) {
        case Roles.Physician:
            routeHandler = new PhysicianRouteHandler()
            break;
        case Roles.Counsellor:
            routeHandler = new NonPhysicianRouteHandler()
            break;
        default:
            routeHandler = new NonPhysicianRouteHandler()
            break;
    }
    return routeHandler.handleRoute(sessionType, notesId, patientProspectId)
}

export const appendFiltersToSearchParams = (searchParams: URLSearchParams, filters: Record<string, any> | undefined) => {
    if (filters) {
        for (const [key, value] of Object.entries(filters)) {
            if (value !== null && value !== undefined) {
                if (Array.isArray(value)) {
                    searchParams.append(key, value.join(','));
                } else {
                    searchParams.append(key, value as string);
                }
            }
        }
    }
};

export const roleTextFormatter = (role: string) => {
    switch (role) {
        case Roles.Admin:
            return "Admin";
        case Roles.Counsellor:
            return "Counsellor";
        case Roles.Physician:
            return "Physician";
        case Roles.Psychiatrist:
            return "Psychiatrist";
        case 'CareCoordinator':
            return "Care Coordinator";
        case 'DataAnalyst':
            return "Data Analyst";
    };
}
