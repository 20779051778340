import React, {PropsWithChildren, useCallback} from "react";
import {useNavigate} from "react-router";
import {SideBarButtonProps, withActiveComponentTreatment, withIconStyling} from "./WithActiveComponentTreatment";

const SMSDashboardButton: React.FC<PropsWithChildren<SideBarButtonProps> & {}> = ({style, navigateTo, iconRenderer}) => {

    const navigate = useNavigate();

    const navigateToScreen = useCallback(() => {
        navigate(navigateTo);
    }, [navigate, navigateTo]);

    function render() {
        return <div className="flex">
            <button
                className={style}
                onClick={navigateToScreen}>
                {iconRenderer("SMS Dashboard")}
            </button>
        </div>;
    }

    return render()
}

export default withActiveComponentTreatment(withIconStyling(SMSDashboardButton))
