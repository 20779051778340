import React, { useEffect } from "react";
import { Link, NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { RoutePaths } from "../../../shared/Utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientBasicInformation } from "../../../store/actions/care-coordinator/patients.action";
import { StateParams } from "../../../store/reducers";
import PatientDetails from "./PatientDetails";
import CoordinatorPatientProfile from "./CoordinatorPatientProfile";
import BackIcon from "../../../assets/images/common/back.svg";
import * as _ from "lodash";


const PatientDetailsScreen = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { patientBasicInformation: patient } = useSelector((state: StateParams) => state.coordinatorPatients);

    useEffect(() => {
        navigate(RoutePaths.careCoordinator.potentialPatients.patientDetails.patientInformation);
    }, []);

    useEffect(() => {
        if (params.patientId) {
            dispatch(fetchPatientBasicInformation(params.patientId));
        }
    }, [params.patientId]);

    function renderBackButton(patient: any) {
        return <div className={"flex flex-row gap-2 items-center"}>
            <Link to={".."} onClick={(e) => {
                e.preventDefault();
                navigate(-1);
            }}>
                <img src={BackIcon} width={24} height={24} alt="Go Back"
                    className={"hover:cursor-pointer"} />
            </Link>
            <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                <span className="text-xl font-semibold text-sjGray"> Patient Details {'|'} </span>
                <span className="text-xl font-semibold"> {_.capitalize(patient.firstName)} {_.capitalize(patient.lastName)} </span>
            </div>
        </div>;
    }

    return (
        <main className="max-h-screen mx-auto flex flex-row bg-[#F7F8FA]">
            <div className="mt-2 px-5 flex-1 flex flex-col">
                <div className="sticky top-0 z-10 bg-[#F7F8FA]">
                    {patient ? renderBackButton(patient) : null}
                    <div className="mt-4">
                        {patient && <CoordinatorPatientProfile patient={patient} />}
                    </div>
                </div>
                <div className="flex-1 mt-6">
                    {patient && <PatientDetails patient={patient} />}
                </div>
            </div>
        </main>
    )
}

export default PatientDetailsScreen
