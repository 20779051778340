import "./AdvancedDateRangePickerComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {DateTime} from "luxon";
import ClickAwayListenerComponent from "../click-away-listener/ClickAwayListenerComponent";
import {toast} from "react-toastify";

interface IAdvancedDateRangePickerComponentProps {
    defaultStartDate?: string;
    defaultEndDate?: string;
    filterOptions?: TFilterOptions[];
    startDate: string;
    endDate: string;
    maxDate?: Date;
    onFilterChange: (startDate: string, endDate: string) => void;
    defaultFilter?: string;
    shouldSelectDateRange?: boolean;
    shouldSetDefaultDatesOnClearAndApply?: boolean;
}

export type TFilterOptions = { value: string, label: string };

const FilterOptions: TFilterOptions[] = [
    {value: "7", label: "7 Days"},
    {value: "30", label: "30 Days"},
    {value: "custom", label: "custom"}
];

const AdvancedDateRangePickerComponent = (props: IAdvancedDateRangePickerComponentProps) => {

    const {shouldSelectDateRange, defaultStartDate, defaultEndDate, shouldSetDefaultDatesOnClearAndApply, startDate, endDate, maxDate, onFilterChange} = props;
    const filterOptions = props.filterOptions || FilterOptions;
    const defaultFilter = props.defaultFilter || "7";
    const [showCustomDateFilter, setShowCustomDateFilter] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<string>(defaultFilter);
    const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(undefined);
    const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if (props.startDate) {
            setFilterStartDate(new Date(DateTime.fromISO(props.startDate).toJSDate()));
        }
        if (props.endDate) {
            setFilterEndDate(new Date(DateTime.fromISO(props.endDate).toJSDate()));
        }
    }, [props.startDate, props.endDate]);

    const handleConfirm = useCallback(() => {
        if (filterStartDate && filterEndDate) {
            onFilterChange?.(
                DateTime.fromJSDate(filterStartDate).startOf('day').toFormat('yyyy-MM-dd'),
                DateTime.fromJSDate(filterEndDate).startOf('day').toFormat('yyyy-MM-dd')
            );
            setSelectedFilter("custom");
            setShowCustomDateFilter(false);
            return;
        }

        if (shouldSelectDateRange && !shouldSetDefaultDatesOnClearAndApply) {
            toast.dismiss();
            toast("Please select a date range");
            return;
        }
        console.log(defaultStartDate, defaultEndDate, shouldSetDefaultDatesOnClearAndApply, defaultFilter);
        if (shouldSetDefaultDatesOnClearAndApply && defaultStartDate && defaultEndDate) {
            setFilterStartDate(DateTime.fromISO(defaultStartDate).toJSDate());
            setFilterEndDate(DateTime.fromISO(defaultEndDate).toJSDate());
            setSelectedFilter(defaultFilter);
            onFilterChange?.(defaultStartDate, defaultEndDate);
        } else {
            onFilterChange?.('', '');
        }

        setShowCustomDateFilter(false);
    }, [shouldSelectDateRange, defaultFilter, defaultStartDate, defaultEndDate, shouldSetDefaultDatesOnClearAndApply, onFilterChange, filterStartDate, filterEndDate]);

    const handleCancel = useCallback(() => {
        if (startDate) {
            setFilterStartDate(new Date(DateTime.fromISO(startDate).toJSDate()));
        } else {
            setFilterStartDate(undefined);
        }
        if (endDate) {
            setFilterEndDate(new Date(DateTime.fromISO(endDate).toJSDate()));
        } else {
            setFilterEndDate(undefined);
        }
        setShowCustomDateFilter(false);
    }, [startDate, endDate]);

    const handleFilterClick = useCallback((filter: TFilterOptions) => {
        if (filter.value === "custom") {
            setShowCustomDateFilter(true);
        } else if (filter.value === "currentDay") {
            const newStartDate = DateTime.local().toFormat('yyyy-MM-dd');
            const newEndDate = DateTime.local().toFormat('yyyy-MM-dd');
            onFilterChange(newStartDate, newEndDate);
            setShowCustomDateFilter(false);
            setSelectedFilter(filter.value);
        } else if (filter.value === "monthToDate") {
            const newStartDate = DateTime.local().startOf('month').toFormat('yyyy-MM-dd');
            const newEndDate = DateTime.local().toFormat('yyyy-MM-dd');
            onFilterChange(newStartDate, newEndDate);
            setShowCustomDateFilter(false);
            setSelectedFilter(filter.value);
        } else if (filter.value === "currentMonth") {
            const newStartDate = DateTime.local().startOf('month').toFormat('yyyy-MM-dd');
            const newEndDate = DateTime.local().endOf('month').toFormat('yyyy-MM-dd');
            onFilterChange(newStartDate, newEndDate);
            setShowCustomDateFilter(false);
            setSelectedFilter(filter.value);
        } else {
            const filterValue = parseInt(filter.value);
            const newStartDate = DateTime.local().minus({days: filterValue - 1}).toFormat('yyyy-MM-dd');
            const newEndDate = DateTime.local().endOf('day').toFormat('yyyy-MM-dd');
            onFilterChange(newStartDate, newEndDate);
            setShowCustomDateFilter(false);
            setSelectedFilter(filter.value);
        }
    }, [onFilterChange]);

    const handleChange = useCallback((dates: [Date, Date]) => {
        const [start, end] = dates;
        setFilterStartDate(start);
        setFilterEndDate(end);
    }, []);

    const handleClear = useCallback(() => {
        setFilterStartDate(undefined);
        setFilterEndDate(undefined);
    }, []);

    return (
        <div className="advanced-date-range-picker-component border-[1.3px] rounded-md border-gray-300">
            {
                showCustomDateFilter && (
                    <div className="custom-date-filter-wrapper">
                        <ClickAwayListenerComponent
                            onClickOutside={handleCancel}>
                            <DatePicker
                                onChange={handleChange}
                                startDate={filterStartDate}
                                endDate={filterEndDate}
                                maxDate={maxDate}
                                selectsRange
                                inline
                            >
                                <div className="custom-date-filter-actions">
                                    <button
                                        onClick={handleCancel}
                                        className="rounded-l-md text-sjDarkGray py-2 px-2"
                                    >Cancel
                                    </button>
                                    <button
                                        onClick={handleClear}
                                        className="rounded-l-md border-sjOrange text-sjOrange py-2 px-4"
                                    >Clear
                                    </button>
                                    &nbsp;
                                    <button
                                        onClick={handleConfirm}
                                        className="bg-sj2 bg-sjOrange text-white py-2 px-4 rounded"
                                    >Apply
                                    </button>
                                </div>
                            </DatePicker>
                        </ClickAwayListenerComponent>
                    </div>
                )
            }
            {filterOptions.map((filter, index) => (
                <div
                    key={index}
                    onClick={() => handleFilterClick(filter)}
                    className={classNames("filter-option-item", selectedFilter === filter.value ? "active" : "")}
                >
                    {filter.value === "custom" ?
                        <>{selectedFilter === 'custom' ? <> {DateTime.fromISO(startDate).toFormat('MM-dd-yyyy')} to {DateTime.fromISO(endDate).toFormat('MM-dd-yyyy')} </> : "Custom"}</>
                        : filter.label}
                </div>
            ))}
        </div>
    );
};

export default AdvancedDateRangePickerComponent;
