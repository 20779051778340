import React, {PropsWithChildren, useEffect, useRef} from "react";

interface IClickAwayListenerComponentProps {
    onClickOutside?: () => void;
}

const ClickAwayListenerComponent: React.FC<React.PropsWithChildren<IClickAwayListenerComponentProps>> = (props: PropsWithChildren<IClickAwayListenerComponentProps>) => {

    const { onClickOutside, children } = props;

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                onClickOutside?.();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClickOutside]);

    return (
        <div
            className=""
        >
            <div ref={wrapperRef} className="bg-white rounded-lg shadow-lg">
                {children}
            </div>
        </div>
    );
};

export default ClickAwayListenerComponent;
