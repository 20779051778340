import React, {useEffect, useState} from 'react'
import sjLogo from "../../assets/images/logos/logo.svg";
import {useNavigate} from "react-router";
import {Link, Outlet, useLocation} from "react-router-dom";

const enum LoginTypes {
    "physician", "non-physician", "clinic-staff", "home"
}

type LoginType = keyof typeof LoginTypes

const HOST_TO_CARE_APP : {[key: string]: string} = {
    "localhost": "http://localhost:9000",
    "care.dev.smalljoys.life": "https://care-app.dev.smalljoys.life",
    "care.uat.smalljoys.life": "https://care-app.uat.smalljoys.life",
    "care.prod.smalljoys.life": "https://care-app.prod.smalljoys.life"
}

const HomeScreen = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [activeComponent, setActiveComponent] = useState<LoginType | null>(null)

    useEffect(() => {
        const pathComponents = location.pathname.split("/")
        console.log("path components", pathComponents)
        try {
            const activatePathComponent = pathComponents[pathComponents.length - 1] as LoginType
            console.log("activate path", activatePathComponent)
            setActiveComponent(activatePathComponent)
        } catch (e) {
            console.log("No matching Login component")
            setActiveComponent("home")
        }
    }, [location.pathname])

    const renderLoginButton = (title: string, expertise: string, isLink: boolean, variant: "primary" | "secondary" | "tertiary") => {
        const background = variant === "primary" || variant === "secondary" ? "bg-sjOrange" : "bg-sjDarkGray"
        const margin = variant === "primary" || variant === "secondary" ? "m-5" : "mt-2"
        return <div className={`${margin} flex`}>
            {isLink ? <Link
                    className={`text-white ${background} focus:ring-4 focus:ring-sjOrange font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none`}
                    to={`${expertise}`}
                >{title}</Link>
                : <button
                    className={`text-white ${background} focus:ring-4 focus:ring-sjOrange font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none`}
                    onClick={() => {
                        console.log(window.location.hostname)
                        const host = window.location.hostname
                        window.location.href = HOST_TO_CARE_APP[host]
                    }}>
                    {title}
                </button>
            }
        </div>
    }

    const renderOutlet = (className : string) => {
        return (
            <div className={className}>
                <Outlet/>
            </div>
        )
    }

    function renderHomeScreen() {
        return (
            <main className="w-full 0 px-3 border-b xs:h-auto md:h-screen flex flex-row ">
                <div className="container w-[85%] flex flex-col items-center">
                    <div className='w-full h-32 flex items-center justify-center'>
                        <img src={sjLogo}/>
                    </div>

                    <div className='w-full h-32 flex items-center justify-center flex-row'>
                        {renderLoginButton("Physician", "physician", true, "primary")}
                        {renderLoginButton("Clinic Staff", "clinic-staff", false, "secondary")}
                    </div>
                </div>
                <div className='container w-[15%] items-start flex justify-center'>
                    {renderLoginButton("Smalljoys Admin", "non-physician", true, "tertiary")}
                </div>

            </main>
        );
    }


    return <>
        {activeComponent && activeComponent !== "home" ? renderOutlet("") : renderHomeScreen()}
    </>
}

export default HomeScreen
