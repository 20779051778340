import React, { useEffect, useState } from "react";
import ProspectProfile from "../../../../components/prospects/ProspectProfile";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { IProspect } from "../../../../models";
import BackIcon from "../../../../assets/images/common/back.svg"
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../../../store/reducers";
import { clearErrors } from "../../../../store/actions/counsellor/appointments.action";
import * as _ from "lodash"
import { fetchProspectWithSummary } from "../../../../store/actions/counsellor/prospects.action";
import ReferredPatientsSideBar from "./ReferredPatientSideBar";

const ReferredPatientsDetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { prospect } = useSelector((state: StateParams) => state.prospects)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        const practiceId = searchParams.get('practiceId')
        const sourcePatientId = searchParams.get('sourcePatientId')
        dispatch(clearErrors())
        if (params.prospectId && practiceId) {
            dispatch(fetchProspectWithSummary(String(params.prospectId), String(practiceId), String(sourcePatientId)))
        }
    }, [params.prospectId])


    function renderBackButton(patient: IProspect) {
        return <div className={"flex flex-row gap-2 items-center"}>
            <Link to={".."} onClick={(e) => {
                e.preventDefault();
                navigate(-1);
            }}>
                <img src={BackIcon} width={24} height={24} alt="Go Back"
                    className={"hover:cursor-pointer"} />
            </Link>
            <div id={"link-section"} className={"flex flex-row gap-2 items-center"}>
                <span className="text-xl font-semibold text-sjGray"> Patient Details {'|'} </span>
                <span className="text-xl font-semibold"> {_.capitalize(patient?.patient?.firstName || patient?.firstName)} {_.capitalize(patient?.patient?.lastName || patient?.lastName)} </span>
            </div>
        </div>;
    }

    return (
        <>
            <main className="max-h-screen mx-auto flex flex-row bg-[#F7F8FA]">
                <div className="mt-2 px-5 flex-1 flex flex-col">
                    <div className="sticky top-0 z-10 bg-[#F7F8FA]">
                        {prospect ? renderBackButton(prospect) : null}
                        <div className="mt-4">
                            {prospect && <ProspectProfile
                                prospect={prospect}
                                isReferredPatient={true}
                                showPatientInfo={false} />}
                        </div>
                    </div>
                    <div className="flex-1 mt-6">
                        {prospect &&
                            <ReferredPatientsSideBar patient={prospect.patient} />
                        }
                    </div>
                </div>
            </main>
        </>
    )
};

export default ReferredPatientsDetails;
