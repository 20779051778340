import {AiOutlineClose} from "react-icons/ai";
import React, {useCallback, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import Message from "../../components/generic/Message";
import {
    IPhysicianAppointmentForm,
    IPracticeLocation,
    IProvider,
    IScreenerUserForScreeningLink,
    Roles
} from "../../models";
import {
    addPhysicianAppointment, fetchAllPatientsForScreenerLink, fetchPatientAllScreeningSessions, fetchPatientMasterList,
    resetAddPhysicianAppointment, resetFetchedAllPatientsForScreenerLink
} from "../../store/actions/care-coordinator/patients.action";
import {toast} from "react-toastify";
import Select from "react-select";
import DatePicker from "react-datepicker";
import TimePickerComponent from "../../components/generic/time-picker/TimePickerComponent";
import {formatTimeZone, getMinutesFromTime, getTimeFromMinutes} from "../../shared/DateUtils";
import _, {debounce} from "lodash";
import {DateTime} from "luxon";
import {useFlags} from "flagsmith/react";
import PatientStatusComponent from "../../components/patients/PatientStatus";
import {GoDotFill} from "react-icons/go";
import {formatPhoneNumber} from "../../shared/Utils";

interface IPhysicianAppointmentFormComponentProps {
    onClose: (refreshList: boolean) => void;
}

const PhysicianAppointmentFormComponent = (props: IPhysicianAppointmentFormComponentProps) => {

    const dispatch = useDispatch();
    const isPatientStatusVisible = useFlags(['physician_appointment_add_show_patient_status'])?.physician_appointment_add_show_patient_status?.enabled;
    const {onClose} = props;
    const {
        addPhysicianAppointmentInProgress,
        addedPhysicianAppointment,
        addPhysicianAppointmentSuccess,
        addPhysicianAppointmentError,
        allPatientsForScreenerLinkList,
        allPatientsForScreenerLinkInProgress,
        patientAllScreeningSessions,
        patientAllScreeningSessionsInProgress,
        patientAllScreeningSessionsSuccess,
        patientAllScreeningSessionsError
    } = useSelector((state: StateParams) => state.coordinatorPatients);
    const [selectedPatient, setSelectedPatient] = useState<IScreenerUserForScreeningLink | undefined>(undefined);
    const {practiceMaster} = useSelector((state: StateParams) => state.careCoordinatorMasterData);
    const [locationMaster, setLocationMaster] = useState<IPracticeLocation[]>([]);
    const [providerMaster, setProviderMaster] = useState<IProvider[]>([]);
    const timeZone = Intl.DateTimeFormat(undefined, {timeZone: 'America/New_York'}).resolvedOptions().timeZone;

    const [formError, setFormError] = useState<string>('');

    const {
        register,
        setValue,
        handleSubmit,
        getValues,
        clearErrors,
        control,
        watch,
        formState: {errors},
    } = useForm<IPhysicianAppointmentForm>({
        defaultValues: {
            practiceId: '',
            locationId: '',
            providerId: '',
            patientId: '',
            appointmentDate: undefined,
            appointmentTime: undefined,
        }
    });

    useEffect(() => {
        if (!addPhysicianAppointmentInProgress && addedPhysicianAppointment && addPhysicianAppointmentSuccess) {
            onClose(true);
            toast("Physician appointment added successfully", {type: "success"});
        }
    }, [addPhysicianAppointmentInProgress, addedPhysicianAppointment, addPhysicianAppointmentSuccess, dispatch, onClose]);

    useEffect(() => {
        if (addPhysicianAppointmentError) {
            setFormError(addPhysicianAppointmentError);
        }
        return () => {
            dispatch(resetAddPhysicianAppointment());
        }
    }, [addPhysicianAppointmentError, dispatch]);

    const onFormChange = useCallback(() => {
        setFormError('');
    }, []);

    const handleDrawerClose = useCallback(() => {
        onClose(false);
        setFormError('');
        dispatch(resetAddPhysicianAppointment());
    }, [onClose, dispatch]);

    const onSubmit = useCallback(handleSubmit((data: IPhysicianAppointmentForm) => {
        console.log("Physician Appointment Form Screen: Save Appointment: ", data);
        const payload = _.cloneDeep(data);
        if (payload.appointmentDate) {
            payload['appointmentDate'] = DateTime.fromJSDate(payload.appointmentDate).startOf('day').setZone("America/New_York", {
                keepLocalTime: true
            }).plus({minute: payload.appointmentTime}).toJSDate();
        }
        dispatch(addPhysicianAppointment(payload));
    }), [dispatch, getValues]);

    const handleSearchPatients = useCallback(debounce((searchText: string) => {
        if (searchText?.trim()?.length) {
            dispatch(fetchAllPatientsForScreenerLink({
                pageNumber: 1,
                recordsPerPage: 10,
                searchText,
                practiceIds: [watch('practiceId')]
            }));
        }
    }, 200), [dispatch, fetchPatientMasterList, watch]);

    const handlePatientSelection = useCallback(() => {
            dispatch(fetchPatientAllScreeningSessions(getValues('patientId'), getValues('practiceId')));
    }, [dispatch, getValues]);

    const renderPatientScreeningSessions = useCallback(() => {
        const latestScreeningSession = patientAllScreeningSessions && patientAllScreeningSessions?.length > 0 ? patientAllScreeningSessions[0] : null;
        const sessionDate = latestScreeningSession ? DateTime.fromJSDate(new Date(latestScreeningSession?.createdAt), {zone: "utc"}).toFormat('MM/dd/yyyy') : '';
        const sessionStatus = latestScreeningSession?.isComplete ? 'Completed' : 'Pending/In-progress';
        const sessionRelativeDate = latestScreeningSession ? DateTime.fromJSDate(new Date(latestScreeningSession?.createdAt), {zone: "utc"}).toRelative() : '';
        return <div className="mt-5 border-2 border-sj2 p-2 rounded-md">
            <>
                {
                    <div>
                        <div className="flex gap-2 mb-2 align-items-center">
                            <label className="block text-sm text-sjDarkGray">
                                Latest Screening Session
                            </label>
                            {isPatientStatusVisible && <>
                                <GoDotFill color={'#cccccc'} size={16}/>
                                {selectedPatient?.status && <PatientStatusComponent patient={selectedPatient}/>}
                            </>
                            }
                        </div>
                        <div className="mt-1">
                            {patientAllScreeningSessionsInProgress && <span>
                                                    Fetching Patient Screening Sessions...
                                                </span>
                            }
                            {patientAllScreeningSessionsError && <span className="text-sjDarkRed">
                                                    {patientAllScreeningSessionsError}
                                                </span>
                            }
                            {
                                (patientAllScreeningSessionsSuccess && patientAllScreeningSessions) &&
                                <div className="text-sm">
                                    {latestScreeningSession ?
                                        <>
                                            {sessionDate} ( {sessionRelativeDate} ) - {sessionStatus}
                                        </>
                                        : 'No Screening Sessions Found'}
                                </div>
                            }
                        </div>
                    </div>
                }
            </>
        </div>
    }, [isPatientStatusVisible, patientAllScreeningSessionsInProgress, patientAllScreeningSessionsError, patientAllScreeningSessionsSuccess, patientAllScreeningSessions, selectedPatient]);

    return (
        <div className={"border-r p-5 h-screen"}>
            <div className="mb-5">
                <div className="flex justify-content-between">
                    <div className="text-2xl font-bold text-[#242731] flex-1 mb-4">Add Appointment</div>
                    <div
                        onClick={handleDrawerClose}
                        className="cursor-pointer grow-0"
                    >
                        <AiOutlineClose
                            className="text-gray-300 hover:text-gray-600"
                            style={{width: '25px', height: '25px'}}
                        />
                    </div>
                </div>
                <div
                    className={"text-base text-[#575F6E] font-light"}>Create a new upcoming appointment for the
                    patient’s visit to the practice.
                </div>
            </div>
            <form onSubmit={onSubmit} onChange={onFormChange}>
                <div>
                    <div className={"grid grid-cols-2 gap-10"}>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Practice*</label>
                            <Controller
                                control={control}
                                name='practiceId'
                                rules={{required: 'This field is required'}}
                                render={({field}) => (
                                    <Select
                                        placeholder={"Enter or Select Practice"}
                                        className="mt-1 outline-none rounded-md"
                                        options={practiceMaster}
                                        getOptionLabel={option => `${option?.name}`}
                                        getOptionValue={option => option?.id}
                                        value={practiceMaster?.find(x => x?.id === field?.value)}
                                        onChange={x => {
                                            if (x?.id === field?.value) return;
                                            dispatch(resetFetchedAllPatientsForScreenerLink());
                                            if (!x || !x?.id) {
                                                setValue('practiceId', '');
                                                setValue('locationId', '');
                                                setValue('providerId', '');
                                                setValue('patientId', '');
                                                setSelectedPatient(undefined);
                                                setLocationMaster([]);
                                                setProviderMaster([]);
                                                field.onChange('');
                                                return;
                                            }
                                            setValue('practiceId', x?.id);
                                            setValue('locationId', '');
                                            setValue('providerId', '');
                                            setValue('patientId', '');
                                            setSelectedPatient(undefined);
                                            field.onChange(x?.id);
                                            const activeLocations = x?.locations?.filter(x => x?.isActive) || [];
                                            const activeProviders = x?.providers?.filter(x => x?.isActive && x?.role?.toLowerCase() !== Roles.Medical_Assistant?.toLowerCase()) || [];
                                            if (activeLocations?.length === 1) {
                                                setValue('locationId', activeLocations[0]?.id);
                                                clearErrors('locationId');
                                            }
                                            if (activeProviders?.length === 1) {
                                                setValue('providerId', activeProviders[0]?.id);
                                                clearErrors('providerId');
                                            }
                                            setLocationMaster(activeLocations || []);
                                            setProviderMaster(activeProviders || []);
                                        }}
                                        isClearable={true}
                                        defaultValue={undefined}
                                        isSearchable={!watch('practiceId')}
                                    />
                                )}
                            />
                            {errors?.practiceId?.message &&
                                <Message message={errors?.practiceId?.message} className={'error-msg text-sm'}/>}
                        </div>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Location*</label>
                            <Controller
                                control={control}
                                name='locationId'
                                rules={{required: 'This field is required'}}
                                render={({field}) => (
                                    <Select
                                        isDisabled={!locationMaster || locationMaster?.length < 2}
                                        placeholder={"Enter or Select Location"}
                                        className="mt-1 outline-none rounded-md"
                                        options={locationMaster}
                                        getOptionLabel={option => `${option?.name}`}
                                        getOptionValue={option => option?.id}
                                        value={locationMaster?.find(x => x?.id === field?.value) || null}
                                        onChange={x => {
                                            if (x?.id === field?.value) return;
                                            if (!x || !x?.id) {
                                                setValue('locationId', '');
                                                field.onChange('');
                                                return;
                                            }
                                            setValue('locationId', x?.id);
                                            field.onChange(x?.id);
                                        }}
                                        isClearable={true}
                                        isSearchable={!watch('locationId')}
                                    />
                                )}
                            />
                            {errors?.locationId?.message &&
                                <Message message={errors?.locationId?.message} className={'error-msg text-sm'}/>}
                        </div>
                    </div>
                    <div className={"grid grid-cols-2 gap-10"}>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Physician*</label>
                            <Controller
                                control={control}
                                name='providerId'
                                rules={{required: 'This field is required'}}
                                render={({field}) => (
                                    <Select
                                        isDisabled={!providerMaster || providerMaster?.length < 2}
                                        placeholder={"Enter or Select Physician"}
                                        className="mt-1 outline-none rounded-md"
                                        options={providerMaster}
                                        getOptionLabel={option => `${option?.firstName} ${option?.lastName}`}
                                        getOptionValue={option => option?.id}
                                        value={providerMaster?.find(x => x?.id === field?.value) || null}
                                        onChange={x => {
                                            if (x?.id === field.value) return;
                                            if (!x || !x?.id) {
                                                setValue('providerId', '');
                                                field.onChange('');
                                                return;
                                            }
                                            setValue('providerId', x?.id);
                                            field.onChange(x?.id);
                                        }}
                                        isClearable={true}
                                        isSearchable={!watch('providerId')}
                                    />
                                )}
                            />
                            {errors?.providerId?.message &&
                                <Message message={errors?.providerId.message} className={'error-msg text-sm'}/>}
                        </div>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Patient*</label>
                            <Controller
                                control={control}
                                name='patientId'
                                rules={{required: 'This field is required'}}
                                render={({field}) => (
                                    <Select
                                        isDisabled={watch('practiceId').length === 0}
                                        placeholder={"Search for Patient"}
                                        className="mt-1 outline-none rounded-md"
                                        options={allPatientsForScreenerLinkList}
                                        formatOptionLabel={(option) => {
                                            return (
                                                <div>
                                                    <div>{`${option?.firstName} ${option?.lastName}`}</div>
                                                    <div
                                                        className="text-sm">{DateTime.fromISO(option?.dob, {zone: 'utc'}).toFormat('MM/dd/yyyy')} | {formatPhoneNumber(option?.contactPhoneNumber)}</div>
                                                </div>
                                            )
                                        }}
                                        getOptionLabel={option => `${option?.firstName} ${option?.lastName}`}
                                        getOptionValue={option => option?.id}
                                        value={allPatientsForScreenerLinkList?.find(x => x?.id === field?.value) || null}
                                        onInputChange={handleSearchPatients}
                                        onChange={x => {
                                            if (x?.id === field.value) return;
                                            if (!x || !x?.id) {
                                                setValue('patientId', '');
                                                field.onChange('');
                                                setSelectedPatient(undefined);
                                                return;
                                            }
                                            setValue('patientId', x?.id);
                                            setSelectedPatient(x);
                                            field.onChange(x?.id);
                                            handlePatientSelection();
                                        }}
                                        isClearable={true}
                                        isSearchable={!watch('patientId')}
                                        noOptionsMessage={() => null}
                                    />
                                )}
                            />
                            {
                                allPatientsForScreenerLinkInProgress &&
                                <div className="text-sjDarkGray text-sm mt-2">Fetching Patients...</div>
                            }
                            {errors?.patientId?.message &&
                                <Message message={errors?.patientId?.message} className={'error-msg text-sm'}/>}
                        </div>
                    </div>
                    {
                        (watch('practiceId') && watch('patientId')) &&
                        <>
                            <>
                                {renderPatientScreeningSessions()}
                            </>
                        </>
                    }
                    <div className={"grid grid-cols-2 gap-10"}>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Appointment Date*
                                ({formatTimeZone(timeZone)})</label>
                            <Controller
                                {...register("appointmentDate", {required: true})}
                                control={control}
                                rules={{required: 'This field is required'}}
                                name='appointmentDate'
                                render={({field}) => (
                                    <DatePicker
                                        className={`mt-[5px] h-[44px] w-full flex-shrink-0 rounded-md border border-gray-300 placeholder-gray-500 ${
                                            errors?.appointmentDate
                                                ? "text-black border-b-sjLightRed"
                                                : "text-black"
                                        }`}
                                        dateFormat="dd MMM yyyy"
                                        placeholderText="Select Appointment Date"
                                        todayButton="Today"
                                        dropdownMode="select"
                                        isClearable
                                        shouldCloseOnSelect
                                        onChange={(date) => field.onChange(date)}
                                        selected={field?.value}
                                    />
                                )}
                            />
                            {errors.appointmentDate?.message &&
                                <Message message={errors.appointmentDate.message} className={'error-msg text-sm'}/>}
                        </div>
                        <div className="">
                            <label className="block text-sm mt-6 text-sjDarkGray">Appointment Time*
                                ({formatTimeZone(timeZone)})</label>
                            <Controller
                                {...register("appointmentTime", {required: true})}
                                control={control}
                                name='appointmentTime'
                                rules={{required: 'This field is required'}}
                                render={({field}) => (
                                    <TimePickerComponent
                                        placeholder="Select Appointment Time"
                                        use12Hours={true}
                                        onChange={(time) => {
                                            const timeInMinutes = getMinutesFromTime(time);
                                            setValue('appointmentTime', timeInMinutes);
                                            field.onChange(timeInMinutes);
                                        }}
                                        hasError={!!errors?.appointmentTime}
                                    />
                                )}
                            />
                            {watch('appointmentTime') !== undefined && <span
                                className="float-right my-2 text-sm">{getTimeFromMinutes(watch('appointmentTime'))} (24 hours format)</span>}
                            {errors?.appointmentTime?.message &&
                                <Message message={errors?.appointmentTime?.message} className={'error-msg text-sm'}/>}
                        </div>
                    </div>
                </div>
                <div className="text-sjDarkRed mt-4 mb-1 pt-2 text-center">{formError}</div>
                <div className={"mt-10"}>
                    <button type={"submit"}
                            disabled={addPhysicianAppointmentInProgress}
                            className={`inline-block text-white rounded shadow py-2 px-5 text-sm ${addPhysicianAppointmentInProgress ? 'bg-sjLightOrange' : 'bg-sjOrange'}`}>
                        {addPhysicianAppointmentInProgress ? "Adding" : "Add Appointment"}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default PhysicianAppointmentFormComponent;
