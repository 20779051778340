import React, {useEffect} from 'react';
import PhysicianAppointmentsOverAllMetricsComponent from "./components/PhysicianAppointmentsOverAllMetricsComponent";
import {fetchPracticeMasterList} from "../../store/actions/care-coordinator/master-data.action";
import {patientCommentsSidebarInActive} from "../../store/actions/care-coordinator/patients.action";
import {useDispatch} from "react-redux";
import PhysicianAppointmentsDayWiseMetricsComponent from "./components/PhysicianAppointmentsDayWiseMetricsComponent";

const SMSDashboardScreen = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPracticeMasterList());
    }, [dispatch]);

    return (
        <div className={"w-full p-4 h-full bg-[#F7F8FA]"}>
            <div className="flex flex-row justify-between items-center flex-wrap gap-2">
                <div className='flex'>
                    <h1 className="text-xl"> SMS Appointments - Dashboard </h1>
                </div>
            </div>
            <div className="flex flex-col gap-5 mt-6">
                <PhysicianAppointmentsOverAllMetricsComponent/>
                <PhysicianAppointmentsDayWiseMetricsComponent/>
            </div>
        </div>
    )
}

export default SMSDashboardScreen;
