import {ICommon, LanguageCode, LanguageCodeDescription} from "../models";

const getRandomColor = () => {
    let letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.round(Math.random() * 15)]
    }
    return color
}

export const GenerateColorCodes = (noOfColors: number) => {
    let colorCodes = []
    for (let i = 0; i < noOfColors; i++) {
        colorCodes.push(getRandomColor())
    }
    return colorCodes
}

export const UserRoles = [
    {
        label: 'Admin',
        value: 'lead'
    },
    {
        label: 'Physician',
        value: 'provider'
    },
    {
        label: 'Medical Assistant',
        value: 'medical_assistant'
    }
]

export const CaptilizeFirstLetter = (str: string) => {
    return str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : ''
}

export const PreferredLanguageFilterOptions: ICommon[] = [
    {value: undefined, label: 'All'},
];

(Object.keys(LanguageCodeDescription) as Array<keyof typeof LanguageCodeDescription>).forEach((key) => {
    if (["en_us", "es"].includes(key)) {
        PreferredLanguageFilterOptions.push({value: key, label: LanguageCodeDescription[key]});
    }
});

export const CalledPatientFilterOptions: ICommon[] = [
    {value: 'Yes', label: 'Yes'},
    {value: 'No', label: 'No'},
];

export const CallResponseFilterOptions: ICommon[] = [
    {value: 'patient_will_fill_out', label: 'Patient will fill out'},
    {value: 'voicemail', label: 'Voicemail'},
    {value: 'patient_refused', label: 'Patient refused'},
    {value: 'others', label: 'Others'}
];

export const SmsStatusFilterOptions: ICommon[] = [
    {value: 'sent', label: 'Sent'},
    {value: 'failed', label: 'Failed'},
    {value: 'pending', label: 'Pending'},
];

export const ScreeningStatusFilterOptions: ICommon[] = [
    {value: 'pending', label: 'Pending'},
    {value: 'in_progress', label: 'In Progress'},
    {value: 'completed', label: 'Completed'},
];

export const AppointmentPreferredLanguageFilterOptions: ICommon[] = [
    {value: 'en_us', label: 'English'},
    {value: 'es', label: 'Spanish'}
];