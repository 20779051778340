import React, {useEffect} from "react";
import {CallMetadata} from "../generic/CallStatus";
import {IPatientDerivedStatus, IScreenerUserForScreeningLink} from "../../models";

const PatientStatusMapping: Record<IPatientDerivedStatus, CallMetadata> = {
    yet_to_be_screened: { description: "Yet to be Screened", style: { backgroundColor: "bg-sjLightGray", color: "text-sjGray" } },
    screened_patient: { description: "Screened Patient", style: { backgroundColor: "bg-sjLightGreen", color: "text-sjDarkGreen" } },
    potential_patient: { description: "Potential Patient", style: { backgroundColor: "bg-sjLightGreen", color: "text-sjDarkGreen" } },
    referred_patient: { description: "Referred Patient", style: { backgroundColor: "bg-sjLightBlue", color: "text-sjBlue" } },
    active_cocm_patient: { description: "Active CoCM Patient", style: { backgroundColor: "bg-sjLightOrange", color: "text-sjOrange" } },
    discharged_patient: { description: "Discharged Patient", style: { backgroundColor: "bg-sjLightRed", color: "text-sjDarkRed" } },
    rejected_from_cocm: { description: "Rejected From CoCM", style: { backgroundColor: "bg-sjLightRed", color: "text-sjDarkRed" } },
    unknown: { description: "Unknown", style: { backgroundColor: "bg-sjLightGray", color: "text-sjGray" } },
}

interface IPatientStatusComponentProps {
    patient: IScreenerUserForScreeningLink;
}

const PatientStatusComponent = (props: IPatientStatusComponentProps) => {

    const { patient } = props;
    const [derivedPatientStatus, setDerivedPatientStatus] = React.useState<IPatientDerivedStatus | null>(null);

    useEffect(() => {
        const { status, screeningDate, isPotentialReferral } = patient;
        let derived_status : IPatientDerivedStatus = 'unknown';
        if (status === 'inactive') {
            if (screeningDate) {
                if (isPotentialReferral) {
                    derived_status = 'potential_patient'
                } else {
                    derived_status = 'screened_patient'
                }
            } else {
                derived_status = 'yet_to_be_screened'
            }
        } else if (status === 'active') {
            derived_status = 'referred_patient'
        } else if (status === 'accepted_to_cocm') {
            derived_status = 'active_cocm_patient'
        } else if (status === 'rejected_from_cocm') {
            derived_status = 'rejected_from_cocm'
        } else if (
            [
                'discharge_reason_patient_got_better',
                'discharge_reason_patient_inactive',
                'discharge_reason_patient_in_need_of_specialized_care',
                'discharge_reason_patient_ineligible',
            ].includes(status)
        ) {
            derived_status = 'discharged_patient'
        } else {
            derived_status = 'unknown'
        }
        setDerivedPatientStatus(derived_status);
    }, [patient]);

    return <>
        {derivedPatientStatus && <div className={`py-1 px-2.5 text-sm font-medium rounded-full shadow-sm ${PatientStatusMapping[derivedPatientStatus]?.style?.backgroundColor} ${PatientStatusMapping[derivedPatientStatus]?.style?.color}`}>
            {PatientStatusMapping[derivedPatientStatus]?.description}
        </div>}
    </>
}

export default PatientStatusComponent;
