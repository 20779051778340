import {Button} from "./Buttons";
import {IoMdRefresh} from "react-icons/io";
import React from "react";
import {Tooltip} from "react-tooltip";

interface IRefreshComponentProps {
    onRefresh: () => void;
}

const RefreshComponent = (props: IRefreshComponentProps) => {

    const {onRefresh} = props;
    return <>
        <Tooltip id="refresh-tooltip" place="top">
            Refresh
        </Tooltip>
        <Button onClick={onRefresh}
                data-tooltip-id="refresh-tooltip"
                className="border-[1.3px] py-2.5 rounded-md border-gray-300">
            <IoMdRefresh/>&nbsp;
        </Button>
    </>
}

export default RefreshComponent;
