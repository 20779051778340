import React from "react";
import { RoutePaths } from "../../../shared/Utils";
import PatientsButton from "../common/PatientsButton";
import { Bars3BottomLeftIcon, DocumentPlusIcon, UsersIcon, ChatBubbleLeftEllipsisIcon, ShieldExclamationIcon } from "@heroicons/react/24/outline";
import { LuCalendarHeart } from "react-icons/lu";
import { RiDashboardLine } from "react-icons/ri";
import PotentialPatientsButton from "../common/PotentialPatientsButton";
import ReferredPatientsButton from "../common/ReferredPatientsButton";
import CareCoordinatiorReferredPatientsButton from "../common/CareCoordinatorReferredPatientsButton";
import { useFlags } from "flagsmith/react";
import PatientMasterButton from "../common/PatientMasterButton";
import ComplianceButton from "../common/ComplianceButton";
import CareLinkButton from "../common/CareLinkButton";
import PhysicianAppointmentsButton from "../common/PhysicianAppointmentsButton";
import SMSDashboardButton from "../common/SMSDashboardButton";

const CareCoordinatorSideBar: React.FC = () => {

    const isComplianceEnabled = useFlags(['non_physician_compliance_for_cc'])?.non_physician_compliance_for_cc?.enabled;
    const isPatientMasterEnabled = useFlags(['cc_patient_master_side_menu_item'])?.cc_patient_master_side_menu_item?.enabled;
    const isPatientInsuranceEnabled = useFlags(['cc_patient_insurance'])?.cc_patient_insurance?.enabled;
    const isSMSDashboardEnabled = useFlags(['cc_sms_dashboard_menu_item'])?.cc_sms_dashboard_menu_item?.enabled;

    function render() {
        return <div className={"flex flex-col"}>
            {isComplianceEnabled && <ComplianceButton
                component={"sw-list"}
                navigateTo={`${RoutePaths.careCoordinator.compliance.root}?page=1`}
                Icon={ShieldExclamationIcon} />}
            <PotentialPatientsButton
                component={"potential-patients"}
                navigateTo={`${RoutePaths.careCoordinator.potentialPatients.root}?page=1`}
                Icon={Bars3BottomLeftIcon} />

            {!isPatientInsuranceEnabled && <ReferredPatientsButton
                component={"referred-patients"}
                navigateTo={`${RoutePaths.careCoordinator.referredPatient.root}?page=1`}
                Icon={DocumentPlusIcon} />
            }
            {isPatientInsuranceEnabled &&
                <CareCoordinatiorReferredPatientsButton
                    component={"cc-referred-patients"}
                    navigateTo={`${RoutePaths.careCoordinator.ccReferredPatients.root}?page=1`}
                    Icon={DocumentPlusIcon} />
            }
            <PatientsButton
                component={"patients"}
                navigateTo={`${RoutePaths.careCoordinator.cocmPatient.root}?page=1`}
                Icon={UsersIcon} />
            {isPatientMasterEnabled && <PatientMasterButton
                component={'patient-master'}
                navigateTo={`${RoutePaths.careCoordinator.patientMaster.root}?page=1`}
                Icon={UsersIcon} />}
            <CareLinkButton
                component={'send-screener-link'}
                navigateTo={`${RoutePaths.careCoordinator.sendScreenerLink}`}
                Icon={LuCalendarHeart}
            />
            <PhysicianAppointmentsButton
                component={"physician-appointments"}
                navigateTo={`${RoutePaths.careCoordinator.physicianAppointments}`}
                Icon={ChatBubbleLeftEllipsisIcon} />
            {isSMSDashboardEnabled && <SMSDashboardButton
                component={"sms-dashboard"}
                navigateTo={`${RoutePaths.careCoordinator.smsDashboard}`}
                Icon={RiDashboardLine} /> }
        </div>
    }

    return <>
        {render()}
    </>
}

export default CareCoordinatorSideBar;
