import { call, put, takeLatest } from "@redux-saga/core/effects";
import { apiHttpClient, getAuthHeaderConfig } from "../../../lib";
import { Either } from "monet";
import { CheckedError, ServiceError } from "../../../types/ServiceError";
import {
    AuthState,
    IAllPatientsForScreenerLink,
    IPatientMaster,
    IInsurancesResponse,
    IPatientMasters,
    IPatientWithSummary,
    IPhysicianAppointmentCreateResponse,
    IPhysicianAppointmentEditForm,
    IPhysicianAppointmentForm,
    IPhysicianAppointmentListResponse,
    IPhysicianAppointmentUpdateResponse,
    IPotentialReferralPatientBasicDetails,
    IInsuranceEligibilityChecks,
    IInsuranceEligibilityCallStatusRequest,
    IReferredPatientsDetails,
    ReferredPatientsAdvancedFilters,
    IPotentialReferralPatientsResponse,
    IScreenerUserForScreeningLink,
    ISendScreenerLinkToPatientResponse,
    PatientMasterIndexRequest,
    SendScreenerLinkToPatientPayload,
    TAllPatientsForScreenerLinkListRequest,
    TPhysicianAppointmentListRequest,
    ISendPhysicianAppointmentReminderRequest,
    ISendPhysicianAppointmentReminderResponse,
    IPatientAllScreeningSessions,
    IInsuranceStatusResponse,
    IPhysicianAppointmentDeleteForm,
    IPhysicianAppointmentDeleteResponse,
    ICommentRequest,
    CommentsResponse,
    ICommentListResponse,
    IPhysicianAppointmentsOverAllMetricsPayload,
    IPhysicianAppointmentsOverAllMetricsResponse,
    IPhysicianAppointmentsDayWiseMetricsPayload, IPhysicianAppointmentsDayWiseMetricsResponse,
} from "../../../models";
import { AxiosError, AxiosResponse } from "axios";
import { callFinished, callInProgress } from "../../actions/loading.action";
import {
    FETCH_PATIENT_LATEST_SCREENING_SUMMARY,
    FETCH_POTENTIAL_REFERRAL_PATIENTS
} from "../../actions/care-coordinator";
import {
    FETCH_ALL_PATIENTS_FOR_SCREENER_LINK,
    FETCH_PATIENT_MASTER_LIST,
    FETCH_PHYSICIAN_APPOINTMENTS,
    ADD_PHYSICIAN_APPOINTMENT,
    fetchedAllPatientsForScreenerLink,
    fetchedPatientBasicInformation,
    FETCH_INSURANCE_LIST,
    FETCH_PATIENT_BASIC_INFORMATION,
    fetchedPatientLatestScreeningSummary,
    fetchedPatientLatestScreeningSummaryBySessionId,
    fetchedPatientMasterList,
    fetchedPhysicianAppointments,
    fetchedPotentialReferralPatients,
    fetchingAllPatientsForScreenerLinkFailed,
    fetchingPatientLatestScreeningSummaryFailed,
    fetchingPotentialReferralPatientsFailed,
    fetchingPatientMasterFailed,
    fetchingPhysicianAppointmentsFailed,
    fetchPotentialReferralPatientsInProgress,
    SEND_SCREENER_LINK_TO_PATIENT,
    sendingScreenerLinkToPatientFailed,
    sentScreenerLinkToPatient,
    fetchInsuranceListFailed,
    fetchInsuranceListInProgress,
    fetchInsuranceListSuccess,
    TRANSFER_INTAKE,
    transferedIntake,
    transferingIntakeFailed,
    addedPhysicianAppointment,
    UPDATE_PHYSICIAN_APPOINTMENT,
    updatingPhysicianAppointmentFailed,
    updatedPhysicianAppointment,
    updatingEligibilityInsuranceStatusFailed,
    checkingInsuranceEligibilityFailed,
    updatedEligibilityInsuranceStatusInProgress,
    checkedInsuranceEligibility,
    updatedEligibilityInsuranceStatus,
    FETCH_REFERRED_PATIENTS,
    fetchingReferredPatientsFailed,
    fetchedReferredPatients,
    addingPhysicianAppointmentFailed,
    CHECK_INSURANCE_ELIGIBILITY,
    checkingInsuranceEligibilityInProgress,
    UPDATE_ELIGIBILITY_INSURANCE_STATUS,
    SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT,
    sendingPhysicianAppointmentReminderToPatientFailed,
    sentPhysicianAppointmentReminderToPatient,
    FETCH_PATIENT_ALL_SCREENING_SESSIONS,
    fetchingPatientAllScreeningSessionsFailed,
    fetchedPatientAllScreeningSessions,
    UPDATE_INSURANCE_STATUS,
    updateInsuranceStatusFailed,
    updateInsuranceStatusInProgress,
    updatedInsuranceStatus,
    DELETE_PHYSICIAN_APPOINTMENT,
    deletingPhysicianAppointmentFailed,
    deletedPhysicianAppointment,
    ADD_COMMENT_TO_PATIENT,
    addedCommentToPatient,
    addingCommentToPatientFailed,
    FETCH_PATIENT_COMMENTS,
    fetchingPatientCommentsFailed,
    fetchedPatientComments,
    FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS,
    fetchingPhysicianAppointmentsOverAllMetricsFailed,
    fetchedPhysicianAppointmentsOverAllMetrics,
    fetchingPhysicianAppointmentsDayWiseMetricsFailed,
    fetchedPhysicianAppointmentsDayWiseMetrics,
    FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS,
} from "../../actions/care-coordinator/patients.action";
import { Filters } from "../../../screens/prospects/care-coordinator/PotentialPatientsScreen";
import { catchErrorMessage } from "../../../lib/api-helpers";
import { appendFiltersToSearchParams } from "../../../shared/Utils";

const apiFetchPotentialReferralPatients = (action: {
    type: string,
    payload: { currentPage: number, recordsPerPage: number, searchText: string, filters?: { status: string[] }, advancedFilters?: Filters },
    authState: AuthState
}) => {
    const searchParams = new URLSearchParams()
    const filters = action.payload.advancedFilters

    appendFiltersToSearchParams(searchParams, filters)

    const url = `/care-coordinator/${action.authState.accountId}/patients/potential-referrals?searchText=${action.payload.searchText}&pageNumber=${action.payload.currentPage - 1}&recordsPerPage=${action.payload.recordsPerPage}&${searchParams.toString()}`;
    return apiHttpClient.get<Either<CheckedError, IPotentialReferralPatientsResponse>>(url, { ...getAuthHeaderConfig(action.authState), params: { filters: action.payload.filters } })
        .then((response: AxiosResponse) => {
            return Either.right(response.data as IPotentialReferralPatientsResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPatients(action: {
    type: string,
    payload: { currentPage: number, recordsPerPage: number, searchText: string, filters?: { status: string[] }, advancedFilters?: Filters },
    authState: AuthState
}) {
    try {
        console.log(`CareCoordinator:PatientsSaga:fetchPatients`)
        yield put(callInProgress())
        yield put(fetchPotentialReferralPatientsInProgress())
        const apiFetchPatientsResponse: Either<CheckedError, IPotentialReferralPatientsResponse> = yield call(apiFetchPotentialReferralPatients, action)
        if (apiFetchPatientsResponse.isLeft()) {
            const error = apiFetchPatientsResponse.left()
            if (error.isChecked) {
                console.log("CareCoordinator:PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPotentialReferralPatientsFailed({ error: error.message }));
            } else {
                yield put(fetchingPotentialReferralPatientsFailed({ error: error.message }));
            }
        } else {
            const { patients, count, statusCount } = apiFetchPatientsResponse.right();
            yield put(fetchedPotentialReferralPatients(patients, count, statusCount));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPotentialReferralPatientsFailed({ error: "Error fetching patients!" }));
    } finally {
        yield put(callFinished())
    }
}

const apiFetchPatientLatestScreeningSummary = (action: {
    type: string,
    payload: { patientId: string, practiceId: string, sessionId?: string },
    authState: AuthState
}) => {
    const url = `/${action.authState.accountId}/prospects/${action.payload.patientId}/screening-summary?practiceId=${action.payload.practiceId}&sessionId=${action.payload.sessionId}`;
    return apiHttpClient.get<Either<CheckedError, IPatientWithSummary>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data?.data as IPatientWithSummary)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPatientLatestScreeningSummary(action: {
    type: string,
    payload: { patientId: string, practiceId: string, sessionId?: string },
    authState: AuthState
}) {
    try {
        console.log(`CareCoordinator:PatientsSaga:fetchPatientLatestScreeningSummary`)
        // yield put(callInProgress())
        const apiFetchPatientsResponse: Either<CheckedError, IPatientWithSummary> = yield call(apiFetchPatientLatestScreeningSummary, action)
        if (apiFetchPatientsResponse.isLeft()) {
            const error = apiFetchPatientsResponse.left()
            if (error.isChecked) {
                console.log("CareCoordinator:PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientLatestScreeningSummaryFailed({ error: error.message }));
            } else {
                yield put(fetchingPatientLatestScreeningSummaryFailed({ error: error.message }));
            }
        } else {
            const response = apiFetchPatientsResponse.right();
            const fn = !action.payload.sessionId ? fetchedPatientLatestScreeningSummary : fetchedPatientLatestScreeningSummaryBySessionId;
            yield put(fn(response));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPotentialReferralPatientsFailed({ error: "Error fetching patient latest screening summary!" }));
    } finally {
        // yield put(callFinished())
    }
}

const apiFetchPatientBasicInformation = (action: {
    type: string,
    payload: { patientId: string },
    authState: AuthState
}) => {
    const url = `/care-coordinator/${action.authState.accountId}/patients/${action.payload.patientId}`;
    return apiHttpClient.get<Either<CheckedError, IPotentialReferralPatientBasicDetails>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data?.patient as IPotentialReferralPatientBasicDetails)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPatientBasicInformation(action: {
    type: string,
    payload: { patientId: string },
    authState: AuthState
}) {
    try {
        console.log(`CareCoordinator:PatientsSaga:fetchPatientBasicInformation`)
        yield put(callInProgress())
        const apiFetchPatientsResponse: Either<CheckedError, IPotentialReferralPatientBasicDetails> = yield call(apiFetchPatientBasicInformation, action)
        if (apiFetchPatientsResponse.isLeft()) {
            const error = apiFetchPatientsResponse.left()
            if (error.isChecked) {
                console.log("CareCoordinator:PatientsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingPatientLatestScreeningSummaryFailed({ error: error.message }));
            } else {
                yield put(fetchingPatientLatestScreeningSummaryFailed({ error: error.message }));
            }
        } else {
            const response = apiFetchPatientsResponse.right();
            yield put(fetchedPatientBasicInformation(response));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPotentialReferralPatientsFailed({ error: "Error fetching patient basic information!" }));
    } finally {
        yield put(callFinished())
    }
}

// apiFetchPatientMasterList
const apiFetchPatientMasterList = (action: { type: string, payload: { request: PatientMasterIndexRequest }, authState: AuthState }) => {
    const searchParams = new URLSearchParams()
    const filters = { practiceIds: action.payload.request.practiceIds, socialWorkerIds: action.payload.request.socialWorkerIds, searchText: action.payload.request.searchText || '' }
    if (filters) {
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                searchParams.append(key, value.toString());
            }
        }
    }
    const url = `/${action.authState.accountId}/prospects/search/patient-master?${searchParams.toString()}&pageNumber=${action.payload.request.pageNumber}&recordsPerPage=${action.payload.request.recordsPerPage}`;
    return apiHttpClient.get<Either<CheckedError, IPatientMasters>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data?.data as IPatientMasters)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchInsuranceList = (action: {
    type: string,
    payload: { patientId: string },
    authState: AuthState
}) => {
    if (!action.authState) {
        throw new Error('authState is undefined');
    }
    const url = `/insurance/${action.payload.patientId}`;
    console.log(action.authState);
    return apiHttpClient.get<Either<CheckedError, IInsurancesResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data.data as IInsurancesResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPatientMasterList(action: { type: string, payload: { request: PatientMasterIndexRequest }, authState: AuthState }) {
    try {
        yield put(callInProgress())
        const apiFetchUserResponse: Either<CheckedError, IPatientMasters> = yield call(apiFetchPatientMasterList, action)
        console.log(apiFetchUserResponse)
        if (apiFetchUserResponse.isLeft()) {
            const error = apiFetchUserResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(fetchingPatientMasterFailed({ error: error.message }));
            } else {
                yield put(fetchingPatientMasterFailed({ error: error.message }));
            }
        } else {
            const { prospects, count } = apiFetchUserResponse.right();
            yield put(fetchedPatientMasterList(prospects, count));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientMasterFailed({ error: "Error fetching patient master list!" }));
    } finally {
        yield put(callFinished())
    }
}

const apiTransferPatient = (action: { type: string, payload: { sourcePatientId: string, therapistId: string | null, patientStatus: string }, authState: AuthState }) => {
    if (!action.authState) {
        throw new Error('authState is undefined');
    }
    const url = `/${action.authState.accountId}/prospects/${action.payload.sourcePatientId}/socialWorker/${action.payload.therapistId}/intake-transfer?patientStatus=${action.payload.patientStatus}`;
    console.log(url);
    return apiHttpClient.put<Either<CheckedError, string>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* transferIntake(action: { type: string, payload: { sourcePatientId: string, therapistId: string | null, patientStatus: string }, authState: AuthState }) {
    console.log(action.authState);
    try {
        yield put(callInProgress())
        const apiSaveUserResponse: Either<CheckedError, string> = yield call(apiTransferPatient, action)
        if (apiSaveUserResponse.isLeft()) {
            const error = apiSaveUserResponse.left()
            if (error.isChecked) {
                yield put(transferingIntakeFailed({ error: error.message }));
            } else {
                yield put(transferingIntakeFailed({ error: error.message }));
            }
        } else {
            const noteId = apiSaveUserResponse.right();
            yield put(transferedIntake(noteId));
        }
    } catch (e) {
        console.log(e)
        yield put(transferingIntakeFailed({ error: "Error transfering intake!" }));
    } finally {
        yield put(callFinished())
    }
}


const apiFetchAllPatientsForScreenerLink = (action: { type: string, payload: TAllPatientsForScreenerLinkListRequest, authState: AuthState }) => {
    const searchParams = new URLSearchParams();
    const filters = { practiceIds: action.payload.practiceIds, search: action.payload.searchText || '' };
    if (filters) {
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                searchParams.append(key, value.toString());
            }
        }
    }
    const url = `/care-coordinator/${action.authState.accountId}/patients?${searchParams.toString()}&pageNumber=${action.payload.pageNumber}&recordsPerPage=${action.payload.recordsPerPage}`;
    return apiHttpClient.get<Either<CheckedError, IScreenerUserForScreeningLink[]>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data?.data as IScreenerUserForScreeningLink[])
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiCheckInsuranceEligibility = (action: { type: string, payload: { insuranceId: string, eligibility: IInsuranceEligibilityChecks }, authState: AuthState }) => {
    const url = `/insurance/${action.payload.insuranceId}/insurance-eligibility-check`;
    return apiHttpClient.post<Either<CheckedError, string>>(url, action.payload.eligibility, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiUpdateInsuranceEligibilityStatus = (action: { type: string, payload: { eligibilityId: string, status: IInsuranceEligibilityCallStatusRequest }, authState: AuthState }) => {
    const url = `/insurance/${action.payload.eligibilityId}/update-insurance-call-status`;
    return apiHttpClient.patch<Either<CheckedError, string>>(url, action.payload.status, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiUpdateInsuranceStatus = (action: { type: string, payload: { patientId: string, status: string }, authState: AuthState }) => {
    const url = `/insurance/${action.payload.patientId}`;
    return apiHttpClient.patch<Either<CheckedError, IInsuranceStatusResponse>>(url, { insuranceStatus: action.payload.status }, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as IInsuranceStatusResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

const apiFetchProspects = (action: { type: string, authState: AuthState, payload: { currentPage: number, recordsPerPage: number, activeTab: string, filters?: ReferredPatientsAdvancedFilters, searchText?: string } }) => {
    const searchParams = new URLSearchParams()
    const filters = { ...action.payload.filters, searchText: action.payload.searchText }
    appendFiltersToSearchParams(searchParams, filters)
    const url = `/${action.authState.accountId}/prospects?${searchParams.toString()}&pageNumber=${action.payload.currentPage - 1}&recordsPerPage=${action.payload.recordsPerPage}&activeTab=${action.payload.activeTab}`;
    return apiHttpClient.get<Either<CheckedError, { prospects: IReferredPatientsDetails[], count: number, tabCount: Record<string, number> }>>(url, { headers: { "x-auth-token": action.authState.token } })
        .then((response: AxiosResponse) => {
            return Either.right(response.data.data as { prospects: IReferredPatientsDetails[], count: number, tabCount: Record<string, number> })
        }).catch(e => catchErrorMessage(e));
}

function* fetchAllPatientsForScreenerLink(action: { type: string, payload: TAllPatientsForScreenerLinkListRequest, authState: AuthState }) {
    try {
        const apiFetchUserResponse: Either<CheckedError, IScreenerUserForScreeningLink[]> = yield call(apiFetchAllPatientsForScreenerLink, action)
        if (apiFetchUserResponse.isLeft()) {
            const error = apiFetchUserResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(fetchingAllPatientsForScreenerLinkFailed({ error: error.message }));
            } else {
                yield put(fetchingAllPatientsForScreenerLinkFailed({ error: error.message }));
            }
        } else {
            const patients = apiFetchUserResponse.right();
            yield put(fetchedAllPatientsForScreenerLink(patients));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingAllPatientsForScreenerLinkFailed({ error: "Error fetching patient master list!" }));
    } finally {
        yield put(callFinished())
    }
}

const apiSendScreenerLinkToPatient = (action: { type: string, payload: SendScreenerLinkToPatientPayload, authState: AuthState }) => {
    const {
        patientId,
        practiceId
    } = action.payload
    const url = `/care-coordinator/${action.authState.accountId}/patients/${patientId}/messages/screener-link`;
    return apiHttpClient.post<Either<CheckedError, ISendScreenerLinkToPatientResponse>>(url, {
        practiceId
    }, getAuthHeaderConfig(action.authState),)
        .then((response: AxiosResponse) => {
            return Either.right(response?.data as ISendScreenerLinkToPatientResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* sendScreenerLinkToPatient(action: { type: string, payload: SendScreenerLinkToPatientPayload, authState: AuthState }) {
    try {
        const apiFetchUserResponse: Either<CheckedError, ISendScreenerLinkToPatientResponse> = yield call(apiSendScreenerLinkToPatient, action)
        if (apiFetchUserResponse.isLeft()) {
            const error = apiFetchUserResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(sendingScreenerLinkToPatientFailed({ error: error.message }));
            } else {
                yield put(sendingScreenerLinkToPatientFailed({ error: error.message }));
            }
        } else {
            yield put(sentScreenerLinkToPatient());
        }
    } catch (e) {
        console.log(e)
        yield put(sendingScreenerLinkToPatientFailed({ error: "Error sending screener link to patient!" }));
    }
}

const apiFetchPhysicianAppointments = (action: { type: string, payload: TPhysicianAppointmentListRequest, authState: AuthState }) => {
    const filters = action.payload;
    const searchParams = new URLSearchParams()
    if (filters) {
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                searchParams.append(key, value.toString());
            }
        }
    }
    const url = `/physician-appointments?${searchParams.toString()}`;
    return apiHttpClient.get<Either<CheckedError, IPhysicianAppointmentListResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data as IPhysicianAppointmentListResponse);
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPhysicianAppointments(action: { type: string, payload: TPhysicianAppointmentListRequest, authState: AuthState }) {
    try {
        yield put(callInProgress())
        const apiResponse: Either<CheckedError, IPhysicianAppointmentListResponse> = yield call(apiFetchPhysicianAppointments, action)
        if (apiResponse.isLeft()) {
            const error = apiResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(fetchingPhysicianAppointmentsFailed({ error: error.message }));
            } else {
                yield put(fetchingPhysicianAppointmentsFailed({ error: error.message }));
            }
        } else {
            const response = apiResponse.right();
            yield put(fetchedPhysicianAppointments(response));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientMasterFailed({ error: "Error fetching patient master list!" }));
    } finally {
        yield put(callFinished())
    }
}

const apiAddPhysicianAppointment = (action: { type: string, payload: IPhysicianAppointmentForm, authState: AuthState }) => {
    const url = `/physician-appointments`;
    return apiHttpClient.post<Either<CheckedError, IPhysicianAppointmentCreateResponse>>(url, action.payload, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data as IPhysicianAppointmentCreateResponse);
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* addPhysicianAppointment(action: { type: string, payload: IPhysicianAppointmentForm, authState: AuthState }) {
    try {
        yield put(callInProgress());
        const apiResponse: Either<CheckedError, IPhysicianAppointmentCreateResponse> = yield call(apiAddPhysicianAppointment, action);
        if (apiResponse.isLeft()) {
            const error = apiResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(addingPhysicianAppointmentFailed({ error: error.message }));
            } else {
                yield put(addingPhysicianAppointmentFailed({ error: error.message }));
            }
        } else {
            yield put(addedPhysicianAppointment(apiResponse.right()));
        }
    } catch (e) {
        console.log(e)
        yield put(addingPhysicianAppointmentFailed({ error: "Error adding physician appointment!" }));
    } finally {
        yield put(callFinished());
    }
}

const apiUpdatePhysicianAppointment = (action: { type: string, payload: { appointmentId: string, requestPayload: IPhysicianAppointmentEditForm }, authState: AuthState }) => {
    const url = `/physician-appointments/${action.payload.appointmentId}`;
    return apiHttpClient.patch<Either<CheckedError, IPhysicianAppointmentUpdateResponse>>(url, action.payload.requestPayload, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data as IPhysicianAppointmentUpdateResponse);
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* updatePhysicianAppointment(action: { type: string, payload: { appointmentId: string, requestPayload: IPhysicianAppointmentEditForm }, authState: AuthState }) {
    try {
        yield put(callInProgress());
        const apiResponse: Either<CheckedError, IPhysicianAppointmentUpdateResponse> = yield call(apiUpdatePhysicianAppointment, action);
        if (apiResponse.isLeft()) {
            const error = apiResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(updatingPhysicianAppointmentFailed({ error: error.message }));
            } else {
                yield put(updatingPhysicianAppointmentFailed({ error: error.message }));
            }
        } else {
            yield put(updatedPhysicianAppointment(apiResponse.right()));
        }
    } catch (e) {
        console.log(e)
        yield put(updatingPhysicianAppointmentFailed({ error: "Error adding physician appointment!" }));
    } finally {
        yield put(callFinished());
    }
}

const apiDeletePhysicianAppointment = (action: { type: string, payload: { appointmentId: string, requestPayload: IPhysicianAppointmentDeleteForm }, authState: AuthState }) => {
    const filters = action.payload.requestPayload;
    const params = new URLSearchParams()
    if (action.payload.requestPayload) {
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                params.append(key, value.toString());
            }
        }
    }
    const url = `/physician-appointments/${action.payload.appointmentId}?${params.toString()}`;
    return apiHttpClient.delete<Either<CheckedError, IPhysicianAppointmentDeleteResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data as IPhysicianAppointmentDeleteResponse);
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* deletePhysicianAppointment(action: { type: string, payload: { appointmentId: string, requestPayload: IPhysicianAppointmentDeleteForm }, authState: AuthState }) {
    try {
        yield put(callInProgress());
        const apiResponse: Either<CheckedError, IPhysicianAppointmentDeleteResponse> = yield call(apiDeletePhysicianAppointment, action);
        if (apiResponse.isLeft()) {
            const error = apiResponse.left();
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message);
                yield put(deletingPhysicianAppointmentFailed({ error: error.message }));
            } else {
                yield put(deletingPhysicianAppointmentFailed({ error: error.message }));
            }
        } else {
            yield put(deletedPhysicianAppointment(apiResponse.right()));
        }
    } catch (e) {
        console.log(e)
        yield put(deletingPhysicianAppointmentFailed({ error: "Error adding physician appointment!" }));
    } finally {
        yield put(callFinished());
    }
}



function* fetchPatientInsuranceList(action: { type: string, payload: { patientId: string }, authState: AuthState }) {
    try {
        yield put(fetchInsuranceListInProgress())
        const apiFetchInsuranceResponse: Either<CheckedError, IInsurancesResponse[]> = yield call(apiFetchInsuranceList, action)
        console.log(apiFetchInsuranceResponse)
        if (apiFetchInsuranceResponse.isLeft()) {
            const error = apiFetchInsuranceResponse.left()
            if (error.isChecked) {
                yield put(fetchInsuranceListFailed({ error: error.message }));
            } else {
                yield put(fetchInsuranceListFailed({ error: error.message }));
            }
        } else {
            yield put(fetchInsuranceListSuccess(apiFetchInsuranceResponse.right()));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchInsuranceListFailed({ error: "Error fetching Insurance list!" }));
    } finally {
    }
}

function* checkInsuranceEligibility(action: { type: string, payload: { insuranceId: string, eligibility: IInsuranceEligibilityChecks }, authState: AuthState }) {
    try {
        yield put(checkingInsuranceEligibilityInProgress())
        const checkInsuranceEligibilityResponse: Either<CheckedError, string> = yield call(apiCheckInsuranceEligibility, action)
        if (checkInsuranceEligibilityResponse.isLeft()) {
            const error = checkInsuranceEligibilityResponse.left()
            if (error.isChecked) {
                yield put(checkingInsuranceEligibilityFailed({ error: error.message }));
            } else {
                yield put(checkingInsuranceEligibilityFailed({ error: error.message }));
            }
        } else {
            yield put(checkedInsuranceEligibility(checkInsuranceEligibilityResponse.right()));
        }
    } catch (e) {
        yield put(checkingInsuranceEligibilityFailed({ error: "Error fetching Insurance list!" }));
    }
    finally {
    }
}

function* updateInsuranceEligibilityStatus(action: { type: string, payload: { eligibilityId: string, status: IInsuranceEligibilityCallStatusRequest }, authState: AuthState }) {
    try {
        yield put(updatedEligibilityInsuranceStatusInProgress())
        const checkInsuranceEligibilityResponse: Either<CheckedError, string> = yield call(apiUpdateInsuranceEligibilityStatus, action)
        if (checkInsuranceEligibilityResponse.isLeft()) {
            const error = checkInsuranceEligibilityResponse.left()
            if (error.isChecked) {
                yield put(updatingEligibilityInsuranceStatusFailed({ error: error.message }));
            } else {
                yield put(updatingEligibilityInsuranceStatusFailed({ error: error.message }));
            }
        } else {
            yield put(updatedEligibilityInsuranceStatus(checkInsuranceEligibilityResponse.right()));
        }

    }
    catch (e) {
        console.log(e)
        yield put(updatingEligibilityInsuranceStatusFailed({ error: "Error fetching Insurance list!" }));
    }
    finally {
    }
}

function* updateInsuranceStatus(action: { type: string, payload: { patientId: string, status: string }, authState: AuthState }) {
    try {
        yield put(updateInsuranceStatusInProgress())
        yield put(callInProgress())
        const checkInsuranceEligibilityResponse: Either<CheckedError, IInsuranceStatusResponse> = yield call(apiUpdateInsuranceStatus, action)
        if (checkInsuranceEligibilityResponse.isLeft()) {
            const error = checkInsuranceEligibilityResponse.left()
            if (error.isChecked) {
                yield put(updateInsuranceStatusFailed({ error: error.message }));
            } else {
                yield put(updateInsuranceStatusFailed({ error: error.message }));
            }
        } else {
            const { id, insuranceStatus } = checkInsuranceEligibilityResponse.right();
            yield put(updatedInsuranceStatus(id, insuranceStatus)); // Pass extracted values
        }

    }
    catch (e) {
        console.log(e)
        yield put(updateInsuranceStatusFailed({ error: "Error fetching Insurance list!" }));
    }
    finally {
        yield put(callFinished())
    }
}

function* fetchReferredPatients(action: { type: string, authState: AuthState, payload: { currentPage: number, recordsPerPage: number, activeTab: string, filters?: ReferredPatientsAdvancedFilters, searchText?: string } }) {
    try {
        yield put(callInProgress())
        const apiFetchProspectsResponse: Either<CheckedError, { prospects: IReferredPatientsDetails[], count: number, tabCount: Record<string, number> }> = yield call(apiFetchProspects, action)
        if (apiFetchProspectsResponse.isLeft()) {
            const error = apiFetchProspectsResponse.left()
            if (error.isChecked) {
                console.log("ProspectsSaga: Encountered a Checked Error", error.message)
                yield put(fetchingReferredPatientsFailed({ error: error.message }));
            } else {
                yield put(fetchingReferredPatientsFailed({ error: error.message }));
            }
        } else {
            const prospects = apiFetchProspectsResponse.right().prospects;
            const prospectsCount = apiFetchProspectsResponse.right().count
            const tabCount = apiFetchProspectsResponse.right().tabCount
            yield put(fetchedReferredPatients(prospects, prospectsCount, tabCount));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingReferredPatientsFailed({ error: "Error fetching prospects!" }));
    } finally {
        yield put(callFinished())
    }
}

const apiSendPhysicianAppointmentReminderToPatient = (action: { type: string, payload: ISendPhysicianAppointmentReminderRequest, authState: AuthState }) => {
    const {
        appointmentId,
    } = action.payload
    const url = `physician-appointments/${appointmentId}/send-reminder-sms`;
    return apiHttpClient.post<Either<CheckedError, ISendPhysicianAppointmentReminderResponse>>(url, {},
        getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data as ISendScreenerLinkToPatientResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e?.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response)
            return Either.left(new Error(e?.response?.data?.error))
        });

}

const apiAddCommentToPatient = (action: { type: string, payload: { patientId: string, commentreq: ICommentRequest }, authState: AuthState }) => {
    const {
        patientId,
        commentreq
    } = action.payload
    const url = `/patient-comments/${patientId}`;
    return apiHttpClient.post<Either<CheckedError, CommentsResponse>>(url, commentreq, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data as CommentsResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e?.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response)
            return Either.left(new Error(e?.response?.data?.error))
        });
}

const apiFetchPatientComments = (action: { type: string, payload: { patientId: string, currentPage: number, recordsPerPage: number, }, authState: AuthState }) => {
    const url = `/patient-comments/${action.payload.patientId}?pageNumber=${action.payload.currentPage}&recordsPerPage=${action.payload.recordsPerPage}`;
    return apiHttpClient.get<Either<CheckedError, ICommentListResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data as ICommentListResponse)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e?.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response)
            return Either.left(new Error(e?.response?.data?.error))
        });
}

function* sendPhysicianAppointmentReminderToPatient(action: { type: string, payload: ISendPhysicianAppointmentReminderRequest, authState: AuthState }) {
    try {
        const apiResponse: Either<CheckedError, ISendPhysicianAppointmentReminderResponse> = yield call(apiSendPhysicianAppointmentReminderToPatient, action)
        if (apiResponse.isLeft()) {
            const error = apiResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(sendingPhysicianAppointmentReminderToPatientFailed({ error: error.message }));
            } else {
                yield put(sendingPhysicianAppointmentReminderToPatientFailed({ error: error.message }));
            }
        } else {
            yield put(sentPhysicianAppointmentReminderToPatient());
        }
    } catch (e) {
        console.log(e)
        yield put(sendingPhysicianAppointmentReminderToPatientFailed({ error: "Error sending screener link to patient!" }));
    }
}

const apiFetchPatientAllScreeningSessions = (action: { type: string, payload: { patientId: string, practiceId: string }, authState: AuthState }) => {
    const url = `/${action.authState.accountId}/patients/${action.payload.patientId}/practice/${action.payload.practiceId}/screening-sessions`;
    return apiHttpClient.get<Either<CheckedError, IPatientAllScreeningSessions>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response?.data as IPatientAllScreeningSessions)
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e?.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response)
            return Either.left(new Error(e?.response?.data?.error))
        });

}

function* fetchPatientAllScreeningSessions(action: { type: string, payload: { patientId: string, practiceId: string }, authState: AuthState }) {
    try {
        yield put(callInProgress())
        const apiFetchUserResponse: Either<CheckedError, IPatientAllScreeningSessions> = yield call(apiFetchPatientAllScreeningSessions, action)
        if (apiFetchUserResponse.isLeft()) {
            const error = apiFetchUserResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(fetchingPatientAllScreeningSessionsFailed({ error: error.message }));
            } else {
                yield put(fetchingPatientAllScreeningSessionsFailed({ error: error.message }));
            }
        } else {
            yield put(fetchedPatientAllScreeningSessions(apiFetchUserResponse.right()?.data || []));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientAllScreeningSessionsFailed({ error: "Error fetching patient screening session list!" }));
    } finally {
        yield put(callFinished())
    }
}

function* addCommentToPatient(action: { type: string, payload: { patientId: string, commentreq: ICommentRequest }, authState: AuthState }) {
    try {
        // yield put(callInProgress())
        const apiResponse: Either<CheckedError, CommentsResponse> = yield call(apiAddCommentToPatient, action)
        if (apiResponse.isLeft()) {
            const error = apiResponse.left()
            if (error.isChecked) {
                yield put(addingCommentToPatientFailed({ error: error.message }));
            } else {
                yield put(addingCommentToPatientFailed({ error: error.message }));
            }
        } else {
            yield put(addedCommentToPatient(apiResponse.right()));
        }
    } catch (e) {
        console.log(e)
        yield put(addingCommentToPatientFailed({ error: "Error adding comment to patient!" }));
    } finally {
        // yield put(callFinished())
    }
}

function* fetchPatientComments(action: { type: string, payload: { patientId: string, currentPage: number, recordsPerPage: number, }, authState: AuthState }) {
    try {
        // yield put(callInProgress())
        const apiFetchUserResponse: Either<CheckedError, ICommentListResponse> = yield call(apiFetchPatientComments, action)
        if (apiFetchUserResponse.isLeft()) {
            const error = apiFetchUserResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(fetchingPatientCommentsFailed({ error: error.message }));
            } else {
                yield put(fetchingPatientCommentsFailed({ error: error.message }));
            }
        } else {
            yield put(fetchedPatientComments(apiFetchUserResponse.right()));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientCommentsFailed({ error: "Error fetching patient comments!" }));
    } finally {
        // yield put(callFinished())
    }
}

const apiFetchPhysicianAppointmentsOverAllMetrics = (action: { type: string, payload: IPhysicianAppointmentsOverAllMetricsPayload, authState: AuthState }) => {
    const filters = action.payload;
    const searchParams = new URLSearchParams()
    if (filters) {
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                searchParams.append(key, value.toString());
            }
        }
    }
    const url = `/sms-dashboard/physician-appointments/overall-metrics?${searchParams.toString()}`;
    return apiHttpClient.get<Either<CheckedError, IPhysicianAppointmentsOverAllMetricsResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data as IPhysicianAppointmentsOverAllMetricsResponse);
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}


function* fetchPhysicianAppointmentsOverAllMetrics(action: { type: string, payload: IPhysicianAppointmentsOverAllMetricsPayload, authState: AuthState }) {
    try {
        // yield put(callInProgress())
        const apiResponse: Either<CheckedError, IPhysicianAppointmentsOverAllMetricsResponse> = yield call(apiFetchPhysicianAppointmentsOverAllMetrics, action)
        if (apiResponse.isLeft()) {
            const error = apiResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(fetchingPhysicianAppointmentsOverAllMetricsFailed({ error: error.message }));
            } else {
                yield put(fetchingPhysicianAppointmentsOverAllMetricsFailed({ error: error.message }));
            }
        } else {
            const response = apiResponse.right();
            yield put(fetchedPhysicianAppointmentsOverAllMetrics(response?.data));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientMasterFailed({ error: "Error fetching physician appointments over all metrics!" }));
    } finally {
        // yield put(callFinished())
    }
}

const apiFetchPhysicianAppointmentsDayWiseMetrics = (action: { type: string, payload: IPhysicianAppointmentsDayWiseMetricsPayload, authState: AuthState }) => {
    const filters = action.payload;
    const searchParams = new URLSearchParams()
    if (filters) {
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                searchParams.append(key, value.toString());
            }
        }
    }
    const url = `/sms-dashboard/physician-appointments/day-wise-metrics?${searchParams.toString()}`;
    return apiHttpClient.get<Either<CheckedError, IPhysicianAppointmentsDayWiseMetricsResponse>>(url, getAuthHeaderConfig(action.authState))
        .then((response: AxiosResponse) => {
            return Either.right(response.data as IPhysicianAppointmentsDayWiseMetricsResponse);
        }).catch((e: AxiosError<ServiceError>) => {
            console.log("API Error", e)
            const statusCode = e.response?.status || 500
            if (statusCode >= 400 && statusCode < 500) {
                const errorResponse = e.response?.data?.error || e.response?.statusText;
                console.log("Encountered a 4XX Error", statusCode, errorResponse)
                return errorResponse ? Either.left(new CheckedError(errorResponse)) : Either.left(new Error("Unknown error occurred during GET process"))
            }
            console.log("Encountered a NON-4XX Error", statusCode, e.response?.statusText)
            return Either.left(new Error(e.message))
        });
}

function* fetchPhysicianAppointmentsDayWiseMetrics(action: { type: string, payload: IPhysicianAppointmentsDayWiseMetricsPayload, authState: AuthState }) {
    try {
        // yield put(callInProgress())
        const apiResponse: Either<CheckedError, IPhysicianAppointmentsDayWiseMetricsResponse> = yield call(apiFetchPhysicianAppointmentsDayWiseMetrics, action)
        if (apiResponse.isLeft()) {
            const error = apiResponse.left()
            if (error.isChecked) {
                console.log("patients: Encountered a Checked Error", error.message)
                yield put(fetchingPhysicianAppointmentsDayWiseMetricsFailed({ error: error.message }));
            } else {
                yield put(fetchingPhysicianAppointmentsDayWiseMetricsFailed({ error: error.message }));
            }
        } else {
            const response = apiResponse.right();
            yield put(fetchedPhysicianAppointmentsDayWiseMetrics(response?.data));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientMasterFailed({ error: "Error fetching physician appointments day wise metrics!" }));
    } finally {
        // yield put(callFinished())
    }
}

export default function* patientsSaga() {
    yield takeLatest(FETCH_POTENTIAL_REFERRAL_PATIENTS, fetchPatients)
    yield takeLatest(FETCH_PATIENT_LATEST_SCREENING_SUMMARY, fetchPatientLatestScreeningSummary)
    yield takeLatest(FETCH_PATIENT_BASIC_INFORMATION, fetchPatientBasicInformation)
    yield takeLatest(FETCH_PATIENT_MASTER_LIST, fetchPatientMasterList)
    yield takeLatest(TRANSFER_INTAKE, transferIntake)
    yield takeLatest(FETCH_ALL_PATIENTS_FOR_SCREENER_LINK, fetchAllPatientsForScreenerLink)
    yield takeLatest(SEND_SCREENER_LINK_TO_PATIENT, sendScreenerLinkToPatient)
    yield takeLatest(FETCH_INSURANCE_LIST, fetchPatientInsuranceList)
    yield takeLatest(CHECK_INSURANCE_ELIGIBILITY, checkInsuranceEligibility)
    yield takeLatest(UPDATE_ELIGIBILITY_INSURANCE_STATUS, updateInsuranceEligibilityStatus)
    yield takeLatest(UPDATE_INSURANCE_STATUS, updateInsuranceStatus)
    yield takeLatest(FETCH_REFERRED_PATIENTS, fetchReferredPatients)
    yield takeLatest(FETCH_PHYSICIAN_APPOINTMENTS, fetchPhysicianAppointments)
    yield takeLatest(ADD_PHYSICIAN_APPOINTMENT, addPhysicianAppointment)
    yield takeLatest(UPDATE_PHYSICIAN_APPOINTMENT, updatePhysicianAppointment)
    yield takeLatest(SEND_PHYSICIAN_APPOINTMENT_REMINDER_TO_PATIENT, sendPhysicianAppointmentReminderToPatient)
    yield takeLatest(FETCH_PATIENT_ALL_SCREENING_SESSIONS, fetchPatientAllScreeningSessions)
    yield takeLatest(DELETE_PHYSICIAN_APPOINTMENT, deletePhysicianAppointment)
    yield takeLatest(ADD_COMMENT_TO_PATIENT, addCommentToPatient)
    yield takeLatest(FETCH_PATIENT_COMMENTS, fetchPatientComments)
    yield takeLatest(FETCH_PHYSICIAN_APPOINTMENTS_OVER_All_METRICS, fetchPhysicianAppointmentsOverAllMetrics)
    yield takeLatest(FETCH_PHYSICIAN_APPOINTMENTS_DAY_WISE_METRICS, fetchPhysicianAppointmentsDayWiseMetrics)
}
