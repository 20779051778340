import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFlags } from "flagsmith/react";
import { RoutePaths } from "../../../../shared/Utils";
import { IPatient, IPotentialReferralPatientBasicDetails, IProspect } from "../../../../models";


const PATIENT_SUB_MENU_LIST = [
    {
        key: "patient_information",
        title: "Patient & Practice",
        link: RoutePaths.careCoordinator.ccReferredPatients.patientDetails.patientInformation
    },
    {
        key: 'screeners',
        title: 'Screeners',
        link: RoutePaths.careCoordinator.ccReferredPatients.patientDetails.screenerSummary
    },
    {
        key: 'insurance',
        title: 'Insurance',
        link: RoutePaths.careCoordinator.ccReferredPatients.patientDetails.insurance
    },
    {
        key: 'comments',
        title: 'Comments',
        link: RoutePaths.careCoordinator.ccReferredPatients.patientDetails.comments
    }
]

const ReferredPatientsSideBar: React.FC<{ patient?: IPatient }> = ({ patient }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const flags = useFlags(['cc_patient_insurance', 'cc_patient_comments']);
    const isPatientInsuranceEnabled = flags.cc_patient_insurance.enabled;
    const isCCPatientsCommentsEnabled = flags.cc_patient_comments.enabled
    const [practiceId, setPracticeId] = useState<string | null>(null)
    const [sourcePatientId, setSourcePatientId] = useState<string | null>(null)


    useEffect(() => {
        if (searchParams) {
            setPracticeId(searchParams.get('practiceId'))
            setSourcePatientId(searchParams.get('sourcePatientId'))
        }
    }, [patient])


    const filteredSubMenuList = isPatientInsuranceEnabled && isCCPatientsCommentsEnabled
        ? PATIENT_SUB_MENU_LIST
        : isPatientInsuranceEnabled
            ? PATIENT_SUB_MENU_LIST.filter((menu) => menu.key !== 'comments')
            : isCCPatientsCommentsEnabled
                ? PATIENT_SUB_MENU_LIST.filter((menu) => menu.key !== 'insurance')
                : PATIENT_SUB_MENU_LIST.filter((menu) => menu.key !== 'insurance' && menu.key !== 'comments')


    return (
        <div className={"patient-details-screen h-full flex flex-col"}>
            <div className="patient-sub-menu-wrapper flex flex-row h-full">
                <div className="patient-sub-menu-navigation flex-shrink-0">
                    <div className="patient-sub-menu-list">
                        {filteredSubMenuList.map((item) => {
                            return (
                                <NavLink to={`${item.link}?practiceId=${practiceId}&sourcePatientId=${sourcePatientId}`}
                                    className={`patient-sub-menu-item`}
                                    key={item?.key}>
                                    <span className="patient-sub-menu-item-title">{item.title}</span>
                                </NavLink>
                            )
                        })}
                    </div>
                </div>
                <div className="patient-sub-menu-route-outlet overflow-auto h-[calc(100vh-210px)]">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default ReferredPatientsSideBar
