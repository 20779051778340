import React, { PropsWithChildren, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import NotFoundComponent from "../components/generic/NotFoundComponent";
import HomeScreen from "../screens/home/Home";
import LoginScreen from "../screens/home/LoginScreen";
import LoginUsingGoogle from "../screens/home/LoginUsingGoogle";
import Debug from "../components/debug/Debug";
import LoadingComponent from "../components/generic/LoadingComponent";
import ZoomHomeScreen from '../screens/zoom/Home';
import JoinScreen from '../screens/zoom/JoinScreen';
import CallScreen from '../screens/zoom/CallScreen';
import ProspectsScreen from '../screens/prospects/counsellors/ProspectsScreen';
import SideBar from "../components/sidebar";
import ProspectSummaryComponent from "../components/prospects/ProspectSummaryComponent";
import AppointmentsScreen from "../screens/appointments/AppointmentsScreen";
import AppointmentCreateComponent from "../components/appointments/AppointmentCreateComponent";
import ProspectIntakeComponent from "../components/prospects/ProspectIntakeComponent";
import PatientsScreen from "../screens/patients/counsellors/PatientsScreen";
import PhysicianProspectsScreen from "../screens/prospects/physicians/ProspectsScreen";
import PhysicianProspectScreen from "../screens/prospects/physicians/PatientWithScreeners";
import PhysicianPatientsScreen from "../screens/patients/physicians/PatientsScreen";
import CoordinatorPatientsScreen from "../screens/patients/care-coordinator/PatientsScreen"
import PhysicianDashboardScreen from "../screens/dashboard/physician/PhysicianDashboardScreen";
import CoordinatorPatientDetailsScreen from "../screens/prospects/care-coordinator/PatientDetailsScreen";
import CoordinatorPatientInformationComponent from "../screens/prospects/care-coordinator/PatientInformationComponent";
import PatientSummaryComponent from "../components/patients/PatientSummaryComponent";
import ProspectSchedule from "../components/prospects/ProspectSchedule";
import PatientTreatmentPlanComponent from '../components/clinical-notes/treatment-plan/PatientTreatmentPlanComponent';
import EditProspectIntake from "../components/prospects/EditProspectIntake";
import EditPatientIntake from '../components/patients/EditPatientIntake';
import PatientSchedule from '../components/patients/PatientSchedule';
import PatientProgressNotesComponent from '../components/clinical-notes/progress-notes/PatientProgressNotesComponent';
import PatientDetailsComponent from '../components/patients/PatientDetailsComponent';
import PatientSafetyPlanNotesComponent
    from '../components/clinical-notes/safety-plan-notes/PatientSafetyPlanNotesComponent';
import RoleBasedNavigator from "./RoleBasedNavigator";
import PatientJourney from '../components/patients/engagement/PatientJourney';
import ForgotPassword from "../screens/home/ForgotPassword";
import ResetPassword from "../screens/home/ResetPassword";
import ChangePassword from "../screens/home/ChangePassword";
import { RequireAuth } from "./AuthContext";
import QRCode from "../screens/provider/QRCode";
import ProviderProfileScreen from "../screens/provider/ProviderProfileScreen";
import { ReferScreen } from "../screens/refer/ReferScreen";
import BhScreeningScreen from "../screens/billing/BhScreeningScreen";
import { RoutePaths } from "../shared/Utils";
import { useSelector } from "react-redux";
import { StateParams } from "../store/reducers";
import { useNavigate } from "react-router";
import PatientCatchupNotesComponent from '../components/clinical-notes/catchup-notes/PatientCatchupNotesComponent';
import IndividualPsychiatristNotesComponent
    from '../components/clinical-notes/individual-psychiatrist-notes/IndividualPsychiatristNotesComponent';
import ScreenersComponent from '../components/clinical-notes/screeners/ScreenersComponent';
import NotificationsBar from "../components/notifications";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from 'flagsmith/react';
import LocationsScreen from '../screens/locations/LocationsScreen';
import LocationCreateComponent from '../screens/locations/LocationCreateComponent';
import LocationEditComponent from '../screens/locations/LocationEditComponent';
import PotentialPatientsScreen from '../screens/prospects/care-coordinator/PotentialPatientsScreen';
import PotentialPatientScreenerSummary from '../screens/prospects/care-coordinator/PotentialPatientScreenerSummary';
import LocationsComponent from "../components/locations";
import { MixpanelProvider } from '../analytics/Mixpanel';
import { isCallActive } from "../models";
import { ModalProvider } from "../context/ModalContext";
import DischargeSummary from '../components/prospects/DischargeSummary';
import DischargedPatientsScreen from '../screens/discharged-patients/PatientsScreen';
import CoordinatorAppointmentCreateComponent from '../components/appointments/CoordinatorAppointmentCreateComponent';
import UsersScreen from "../screens/users/UsersScreen";
import UserFormScreen from "../screens/users/UserFormScreen";
import { ToastContainer } from "react-toastify";
import ChangeDefaultPassword from "../screens/home/ChangeDefaultPassword";
import OTPScreen from '../screens/home/OTPScreen';
import ComplianceScreen from "../screens/compliance/ComplianceScreen";
import HelpWidgetComponent from "../components/help-widget/HelpWidgetComponent";
import PatientIntakeToggleComponent from "../components/prospects/PatientIntakeToggleComponent";
import PatientViewIntakeForm from "../components/prospects/PatientViewIntakeForm";
import PatientMasterScreen from '../screens/patient-master/PatientMasterScreen';
import ScreenerPendingPatientsScreen
    from "../screens/compliance/screener-pending-patients/ScreenerPendingPatientsScreen";
import ClinicalNotesPendingListScreen from "../screens/compliance/clinical-notes/ClinicalNotesPendingListScreen";
import BackDatedSchedule from '../components/appointments/BackDatedSchedule';
import SOAPNotesScreen from '../screens/soap/SOAPNotesScreen';
import SWComplianceList from '../screens/compliance/care-coordinator/SWComplianceList';
import InsuranceListComponent from '../screens/prospects/care-coordinator/Insurance-list';
import ReferredPatientsScreen from '../screens/prospects/care-coordinator/referred-patients/ReferredPatientsScreen';
import ReferredPatientsDetails from '../screens/prospects/care-coordinator/referred-patients/ReferredPatientsDetails';
import ReferredPatientInformationComponent from '../screens/prospects/care-coordinator/referred-patients/ReferredPatientInformationComponent';
import ReferredPatientScreenerSummary from '../screens/prospects/care-coordinator/referred-patients/potentialPatientsScreeneringSummary';
import SendScreenerLinkScreen from "../screens/send-screener-link/SendScreenerLinkScreen";
import PhysicianAppointmentsScreen from "../screens/physician-appointments/PhysicianAppointmentsScreen";
import SMSDashboardScreen
    from "../screens/sms-dashboard/SMSDashboardScreen";
import PatientCommentsScreen from '../screens/prospects/care-coordinator/PatientCommentsScreen';

const SideBarLayout: React.FC<PropsWithChildren<{ showSideBar: boolean }>> = ({ showSideBar, children }) => {

    return (<div className="relative min-h-screen mx-auto flex flex-row">
        {showSideBar ? <SideBar /> : null}
        <div className="flex-1 max-h-screen overflow-y-auto" id={"main-content-container"}>
            <LoadingComponent />
            <LocationsComponent />
            {children}
        </div>
    </div>)
}

const LandingPageLayout: React.FC<PropsWithChildren> = ({ children }) => {

    return (<div>
        <LoadingComponent top={"45%"} left={"45%"} />
        <div>
            {children}
        </div>
    </div>)
}

interface NavigationByRoleProps {
    roles: string[]
}

const FLAG_SMITH_ENV: { [key: string]: string } = {
    "care.dev.smalljoys.life": "fMSowWsg7omQZkxABAJo5o",
    "care.uat.smalljoys.life": "8jxsXa5N43VihEtxiKgXpX",
    "localhost": "axe6UkgSDXs4QktJuwru2K",
    "care.prod.smalljoys.life": "jWPHMdZRSRy88FbFzje7P7",
}

const host = window.location.host
const isLocalHost = host.startsWith("localhost")
const FLAG_SMITH_ENV_ID = FLAG_SMITH_ENV[isLocalHost ? "localhost" : host] || "jWPHMdZRSRy88FbFzje7P7"

const NavigationRestriction: React.FC<PropsWithChildren<NavigationByRoleProps>> = ({ roles, children }) => {
    const navigate = useNavigate()
    const role = useSelector((state: StateParams) => state.account.role);

    useEffect(() => {
        console.log("Role is", role)
        console.log("Roles configured for", roles)
        if (role && !roles.includes(role)) {
            console.log("Invalid role for this path")
            navigate("/home", { replace: true })
        }
    }, [role])

    return (<>
        {children}
    </>)
}

const NavigationByRole: React.FC<PropsWithChildren> = ({ children }) => {

    return (<>
        <LandingPageLayout>{children}</LandingPageLayout>
        <RoleBasedNavigator />
    </>)
}

const ComponentWithAuthRequirement = (Component: React.ComponentType, requiresSideBar: boolean) => {
    return (
        <RequireAuth>
            <SideBarLayout showSideBar={requiresSideBar}><Component /></SideBarLayout>
        </RequireAuth>
    )
}

const PhysicianComponent = (Component: React.ComponentType, requiresAuth: boolean = true, requiresSideBar: boolean = true) => {
    return (
        <NavigationRestriction roles={["Physician"]}>
            {requiresAuth ? ComponentWithAuthRequirement(Component, requiresSideBar) : <Component />}
        </NavigationRestriction>
    )
}

const CollaborativeCareComponent = (Component: React.ComponentType, requiresAuth: boolean = true, requiresSideBar: boolean = true) => {
    return (
        <NavigationRestriction roles={["Counsellor", "Admin", "Psychiatrist", "Care_Coordinator"]}>
            {requiresAuth ? ComponentWithAuthRequirement(Component, requiresSideBar) : <Component />}
        </NavigationRestriction>
    )
}

const MedicalAssistantComponent = (Component: React.ComponentType, requiresAuth: boolean = true, requiresSideBar: boolean = true) => {
    return (
        <NavigationRestriction roles={["Medical_Assistant"]}>
            {requiresAuth ? ComponentWithAuthRequirement(Component, requiresSideBar) : <Component />}
        </NavigationRestriction>
    )
}

const Navigator = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path={RoutePaths.app.root} element={<Navigate to="/home" replace />} />
                <Route path={RoutePaths.home.root} element={<NavigationByRole><HomeScreen /></NavigationByRole>}>
                    <Route path={RoutePaths.home.physician} element={<NavigationByRole><LoginScreen /></NavigationByRole>} />
                    <Route path={RoutePaths.home.nonPhysician} element={<NavigationByRole><LoginUsingGoogle /></NavigationByRole>} />
                </Route>
                <Route path={RoutePaths.collaborativeCare.prospects.root}>
                    <Route path={RoutePaths.collaborativeCare.prospects.root} element={CollaborativeCareComponent(ProspectsScreen)}>
                        <Route path={RoutePaths.collaborativeCare.prospects.scheduleIntakeAppointment} element={<ProspectSchedule />} />
                        <Route path={RoutePaths.collaborativeCare.prospects.editIntakeAppointment} element={<EditProspectIntake />} />
                    </Route>
                    <Route path={RoutePaths.collaborativeCare.prospects.summary} element={CollaborativeCareComponent(ProspectSummaryComponent)} />
                    <Route path={RoutePaths.collaborativeCare.prospects.startIntake} element={<ModalProvider>{CollaborativeCareComponent(PatientIntakeToggleComponent)}</ModalProvider>} />
                    <Route path={RoutePaths.collaborativeCare.prospects.viewIntake} element={<ModalProvider>{CollaborativeCareComponent(PatientViewIntakeForm)}</ModalProvider>} />
                </Route>
                <Route path={RoutePaths.collaborativeCare.compliance.root}>
                    <Route path={RoutePaths.collaborativeCare.compliance.root} element={CollaborativeCareComponent(ComplianceScreen)} />
                    <Route path={RoutePaths.collaborativeCare.compliance.screeningPendingPatients} element={CollaborativeCareComponent(ScreenerPendingPatientsScreen)} />
                    <Route path={RoutePaths.collaborativeCare.compliance.journey} element={CollaborativeCareComponent(PatientJourney)} />
                    <Route path={RoutePaths.collaborativeCare.compliance.clinicalNotesPendingList} element={CollaborativeCareComponent(ClinicalNotesPendingListScreen)} />
                </Route>
                <Route path={RoutePaths.collaborativeCare.patients.root} element={CollaborativeCareComponent(PatientsScreen)}>
                    <Route path={RoutePaths.collaborativeCare.patients.scheduleIntakeAppointment} element={<PatientSchedule />} />
                    <Route path={RoutePaths.collaborativeCare.patients.editIntakeAppointment} element={<EditPatientIntake />} />
                </Route>
                <Route path={RoutePaths.collaborativeCare.patients.root}>
                    <Route path={RoutePaths.collaborativeCare.patients.summary} element={CollaborativeCareComponent(PatientSummaryComponent)} />
                    <Route path={RoutePaths.collaborativeCare.patients.journey.root} element={CollaborativeCareComponent(PatientJourney)} >
                        {/* <Route index element={CollaborativeCareComponent(PatientJourney)} /> */}
                        <Route path={RoutePaths.collaborativeCare.patients.journey.scheduleBackDatedAppointment} element={<BackDatedSchedule />} />
                    </Route>
                    <Route path={RoutePaths.collaborativeCare.patients.dischargeSummary} element={CollaborativeCareComponent(DischargeSummary)} />
                    <Route path={RoutePaths.collaborativeCare.patients.createAppointment} element={CollaborativeCareComponent(AppointmentCreateComponent)} />
                    <Route path={RoutePaths.collaborativeCare.patients.treatment} element={CollaborativeCareComponent(PatientTreatmentPlanComponent)} />
                    <Route path={RoutePaths.collaborativeCare.patients.progressNotes} element={CollaborativeCareComponent(PatientProgressNotesComponent)} />
                    <Route path={RoutePaths.collaborativeCare.patients.catchupNotes} element={CollaborativeCareComponent(PatientCatchupNotesComponent)} />
                    <Route path={RoutePaths.collaborativeCare.patients.individualPsychiatristNotes} element={CollaborativeCareComponent(IndividualPsychiatristNotesComponent)} />
                    <Route path={RoutePaths.collaborativeCare.patients.patientDetails} element={CollaborativeCareComponent(PatientDetailsComponent)} />
                    <Route path={RoutePaths.collaborativeCare.patients.safetyPlan} element={CollaborativeCareComponent(PatientSafetyPlanNotesComponent)} />
                    <Route path={RoutePaths.collaborativeCare.patients.screeners} element={CollaborativeCareComponent(ScreenersComponent)} />
                </Route>
                <Route path={RoutePaths.collaborativeCare.dischargedPatients.root}>
                    <Route index element={CollaborativeCareComponent(DischargedPatientsScreen)} />
                    <Route path={RoutePaths.collaborativeCare.dischargedPatients.journey} element={CollaborativeCareComponent(PatientJourney)} />
                    <Route path={RoutePaths.collaborativeCare.dischargedPatients.dischargeSummary} element={CollaborativeCareComponent(DischargeSummary)} />
                </Route>
                <Route path={RoutePaths.collaborativeCare.appointments.root} element={CollaborativeCareComponent(AppointmentsScreen)}>
                    <Route path={RoutePaths.collaborativeCare.appointments.create} element={<AppointmentCreateComponent />} />
                </Route>

                <Route path={RoutePaths.medicalAssistance.prospects} element={MedicalAssistantComponent(PhysicianProspectsScreen)}/>
                <Route path={RoutePaths.medicalAssistance.maProfile} element={MedicalAssistantComponent(ProviderProfileScreen)}/>
                <Route path={RoutePaths.medicalAssistance.prospectProfile} element={MedicalAssistantComponent(PhysicianProspectScreen)} />
                <Route path={RoutePaths.physicianCare.forgotPassword} element={ PhysicianComponent(ForgotPassword, false, false) } />
                <Route path={RoutePaths.physicianCare.otpScreen} element={<NavigationByRole><OTPScreen /></NavigationByRole> }/>
                <Route path={RoutePaths.physicianCare.resetVerification} element={ PhysicianComponent(ResetPassword, false, false) } />
                <Route path={RoutePaths.physicianCare.changePassword} element={ PhysicianComponent(ChangePassword, false, false) } />
                <Route path={RoutePaths.physicianCare.changeDefaultPassword} element={ PhysicianComponent(ChangeDefaultPassword,    false, false) } />
                <Route path={RoutePaths.physicianCare.prospects} element={ PhysicianComponent(PhysicianProspectsScreen) }/>
                <Route path={RoutePaths.physicianCare.patients} element={PhysicianComponent(PhysicianPatientsScreen)}/>
                <Route path={RoutePaths.physicianCare.dashboard} element={PhysicianComponent(PhysicianDashboardScreen)}/>

                <Route path={RoutePaths.physicianCare.patientJourney} element={PhysicianComponent(PatientJourney)} />
                <Route path={RoutePaths.physicianCare.patientDetails} element={(PhysicianComponent(PatientDetailsComponent))} />
                <Route path={RoutePaths.physicianCare.treatment} element={PhysicianComponent(PatientTreatmentPlanComponent)} />
                <Route path={RoutePaths.physicianCare.progressNotes} element={PhysicianComponent(PatientProgressNotesComponent)} />
                <Route path={RoutePaths.physicianCare.catchupNotes} element={PhysicianComponent(PatientCatchupNotesComponent)} />
                <Route path={RoutePaths.physicianCare.individualPsychiatristNotes} element={PhysicianComponent(IndividualPsychiatristNotesComponent)} />
                <Route path={RoutePaths.physicianCare.safetyPlan} element={PhysicianComponent(PatientSafetyPlanNotesComponent)} />
                <Route path={RoutePaths.physicianCare.screeners} element={PhysicianComponent(ScreenersComponent)} />

                <Route path={RoutePaths.physicianCare.prospectProfile} element={PhysicianComponent(PhysicianProspectScreen)} />
                <Route path={RoutePaths.physicianCare.qrCode} element={PhysicianComponent(QRCode, true, false)} />
                <Route path={RoutePaths.physicianCare.providerProfile} element={PhysicianComponent(ProviderProfileScreen)} />
                <Route path={RoutePaths.physicianCare.referPatient} element={PhysicianComponent(ReferScreen)} />
                <Route path={RoutePaths.physicianCare.bhScreening} element={PhysicianComponent(BhScreeningScreen)} />
                <Route path={RoutePaths.physicianCare.locations.root} element={PhysicianComponent(LocationsScreen)}>
                    <Route path={RoutePaths.physicianCare.locations.create} element={<LocationCreateComponent />} />
                    <Route path={RoutePaths.physicianCare.locations.edit} element={<LocationEditComponent />} />
                </Route>

                <Route path={RoutePaths.physicianCare.soap.root} element={PhysicianComponent(SOAPNotesScreen)} />

                {/* User Master (Providers) Routes Start */}
                <Route path={RoutePaths.physicianCare.users.root} element={PhysicianComponent(UsersScreen)}>
                    {/*<Route path={RoutePaths.physicianCare.users.create} element={<UserFormScreen/>} />*/}
                    {/*<Route path={RoutePaths.physicianCare.users.edit} element={<UserFormScreen/>} />*/}
                </Route>
                {/* User Master (Providers) Routes End */}

                <Route path={RoutePaths.physicianCare.dischargedPatients.root}>
                    <Route index element={PhysicianComponent(DischargedPatientsScreen)} />
                    <Route path={RoutePaths.physicianCare.dischargedPatients.journey} element={PhysicianComponent(PatientJourney)} />
                    <Route path={RoutePaths.physicianCare.dischargedPatients.dischargeSummary} element={PhysicianComponent(DischargeSummary)} />
                </Route>
                <Route path={RoutePaths.physicianCare.debug} element={<Debug />} />
                <Route path={RoutePaths.physicianCare.zoom} element={PhysicianComponent(ZoomHomeScreen, true, false)} />
                <Route path={RoutePaths.physicianCare.zoomJoin} element={PhysicianComponent(JoinScreen, true, false)} />
                <Route path={RoutePaths.physicianCare.zoomCall} element={PhysicianComponent(CallScreen, true, false)} />
                <Route path={RoutePaths.notFound} element={<NotFoundComponent />} />

                {/* CARE COORDINATOR ROUTES START */}
                <Route path={RoutePaths.careCoordinator.compliance.root}>
                    <Route path={RoutePaths.careCoordinator.compliance.root} element={CollaborativeCareComponent(SWComplianceList)} />
                    <Route path={RoutePaths.careCoordinator.compliance.clinicalNotesPendingList} element={CollaborativeCareComponent(ClinicalNotesPendingListScreen)} />
                    <Route path={RoutePaths.careCoordinator.compliance.screenerPendingList} element={CollaborativeCareComponent(ScreenerPendingPatientsScreen)} />
                    {/* <Route path={RoutePaths.careCoordinator.compliance.journey} element={CollaborativeCareComponent(PatientJourney)}/> */}
                </Route>

                <Route path={RoutePaths.careCoordinator.potentialPatients.root} >
                    <Route path={RoutePaths.careCoordinator.potentialPatients.root} element={CollaborativeCareComponent(PotentialPatientsScreen)} />
                    <Route path={RoutePaths.careCoordinator.potentialPatients.patientDetails.root} element={CollaborativeCareComponent(CoordinatorPatientDetailsScreen)}>
                        <Route path={RoutePaths.careCoordinator.potentialPatients.patientDetails.patientInformation} element={<CoordinatorPatientInformationComponent />} />
                        <Route path={RoutePaths.careCoordinator.potentialPatients.patientDetails.screenerSummary} element={<PotentialPatientScreenerSummary />} />
                        <Route path={RoutePaths.careCoordinator.potentialPatients.patientDetails.insurance} element={<InsuranceListComponent />} />
                        <Route path={RoutePaths.careCoordinator.potentialPatients.patientDetails.comments} element={<PatientCommentsScreen />} />
                    </Route>
                </Route>
                <Route path={RoutePaths.careCoordinator.cocmPatient.root} >
                    <Route path={RoutePaths.careCoordinator.cocmPatient.root} element={CollaborativeCareComponent(CoordinatorPatientsScreen)} />
                    <Route path={RoutePaths.careCoordinator.cocmPatient.summary} element={CollaborativeCareComponent(PatientSummaryComponent)} />
                    <Route path={RoutePaths.careCoordinator.cocmPatient.journey} element={CollaborativeCareComponent(PatientJourney)} />
                </Route>
                <Route path={RoutePaths.careCoordinator.referredPatient.root} >
                    <Route path={RoutePaths.careCoordinator.referredPatient.root} element={CollaborativeCareComponent(ProspectsScreen)} />
                    <Route path={RoutePaths.careCoordinator.referredPatient.summary.root} element={CollaborativeCareComponent(ProspectSummaryComponent)} >
                        <Route path={RoutePaths.careCoordinator.referredPatient.summary.appointments} element={<CoordinatorAppointmentCreateComponent />} />
                    </Route>
                </Route>

                <Route path={RoutePaths.careCoordinator.ccReferredPatients.root} >
                    <Route path={RoutePaths.careCoordinator.ccReferredPatients.root} element={CollaborativeCareComponent(ReferredPatientsScreen)} />
                    <Route path={RoutePaths.careCoordinator.ccReferredPatients.patientDetails.root} element={CollaborativeCareComponent(ReferredPatientsDetails)}>
                        <Route path={RoutePaths.careCoordinator.ccReferredPatients.patientDetails.appointments} element={<CoordinatorAppointmentCreateComponent/>} />
                        <Route path={RoutePaths.careCoordinator.ccReferredPatients.patientDetails.patientInformation} element={<ReferredPatientInformationComponent />} />
                        <Route path={RoutePaths.careCoordinator.ccReferredPatients.patientDetails.screenerSummary} element={<ReferredPatientScreenerSummary />} />
                        <Route path={RoutePaths.careCoordinator.ccReferredPatients.patientDetails.insurance} element={<InsuranceListComponent />} />
                        <Route path={RoutePaths.careCoordinator.ccReferredPatients.patientDetails.comments} element={<PatientCommentsScreen />} />
                    </Route>
                </Route>
                <Route path={RoutePaths.careCoordinator.sendScreenerLink} element={CollaborativeCareComponent(SendScreenerLinkScreen)} />
                <Route path={RoutePaths.careCoordinator.physicianAppointments} element={CollaborativeCareComponent(PhysicianAppointmentsScreen)} />
                <Route path={RoutePaths.careCoordinator.smsDashboard} element={CollaborativeCareComponent(SMSDashboardScreen)} />
                <Route path={RoutePaths.careCoordinator.patientMaster.root} >
                    <Route path={RoutePaths.careCoordinator.patientMaster.root} element={CollaborativeCareComponent(PatientMasterScreen)} />
                </Route>
                {/* CARE COORDINATOR ROUTES END*/}

              <Route path="*" element={<Navigate to={`/home`} replace />} />
            </Routes>

            <HelpWidgetComponent />
        </BrowserRouter>
    )
};

const MainRouter = () => {
    const { callStatus } = useSelector((state: StateParams) => state.calls)
    const [callActive, setCallActive] = useState(false)
    useEffect(() => {
        setCallActive(isCallActive(callStatus))
    }, [callStatus]);

    return (<FlagsmithProvider options={{ environmentID: FLAG_SMITH_ENV_ID }} flagsmith={flagsmith}>
        <MixpanelProvider>
            <NotificationsBar />
            <main className={callActive ? "mt-[50px]" : ""}>
                <Navigator />
            </main>
            <ToastContainer />
        </MixpanelProvider>
    </FlagsmithProvider>);
};

export default MainRouter;
