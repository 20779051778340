import { useParams } from "react-router-dom";
import CommentListComponent from "../../../components/comments/CommentListComponent";

interface PatientCommentsScreenProps {

}

const PatientCommentsScreen = (props: PatientCommentsScreenProps) => {
    const params = useParams();
    const patientId = params.patientId || params.prospectId

    return <div>
        {patientId && <CommentListComponent patientId={patientId} />}
    </div>
}

export default PatientCommentsScreen;