import React, {useCallback, useEffect, useState} from "react";
import {useFlags} from "flagsmith/react";
import _ from "lodash";

const PatientScreeningStatusOptions = [
    {value: 'pending', code: 'pending', label: 'Pending', bgColor: 'bg-red-100'},
    {value: 'in_progress', code: 'in-progress', label: 'In Progress', bgColor: 'bg-orange-100'},
    {value: 'completed', code: 'completed', label: 'Completed', bgColor: 'bg-green-100'}
];

interface IPhysicianAppointmentScreeningStatusComponentProps {
    value: {
        appointmentId: string;
        appointmentScreeningStatus: string | null;
    },
    updateAppointmentPatientScreeningStatus: (appointmentId: string, appointmentScreeningStatus: string) => void;
}

const PhysicianAppointmentScreeningStatusComponent = (props: IPhysicianAppointmentScreeningStatusComponentProps) => {

    const isEditScreeningStatusEnabled = useFlags(['physician_appointments_edit_screening_status'])?.physician_appointments_edit_screening_status?.enabled;
    const {value, updateAppointmentPatientScreeningStatus} = props;
    const {appointmentId, appointmentScreeningStatus} = value;

    const [selectedOption, setSelectedOption] = useState<string>('');

    useEffect(() => {
        if (appointmentScreeningStatus !== null) {
            setSelectedOption(appointmentScreeningStatus);
        } else {
            setSelectedOption('');
        }
    }, [appointmentScreeningStatus]);

    const handleChange = useCallback((event: any) => {
        const selectedOptionCode = event?.target?.value;
        setSelectedOption(selectedOptionCode);
        const selectedOption = PatientScreeningStatusOptions?.find(option => option.value === selectedOptionCode)?.value;
        if (selectedOption !== undefined) {
            updateAppointmentPatientScreeningStatus(appointmentId, selectedOption);
        }
    }, [updateAppointmentPatientScreeningStatus, appointmentId]);

    return <>
        {
            isEditScreeningStatusEnabled ? <select
                onChange={handleChange}
                value={selectedOption}
                defaultValue={''}
                className={`block min-w-[120px] w-full outline-none py-1 px-4  placeholder-gray-500 rounded-md border border-gray-300 ${selectedOption ? PatientScreeningStatusOptions.find(option => option.value === selectedOption)?.bgColor : ''}`}
            >
                <option value={''} disabled={true}>Select</option>
                {
                    PatientScreeningStatusOptions?.map((option) => {
                        return <option key={option?.code} value={option?.value}>{option?.label}</option>
                    })
                }
            </select> : <div className={`py-1 text-center px-2 rounded-md ${selectedOption ? PatientScreeningStatusOptions?.find(option => option?.value === selectedOption)?.bgColor : ''}`}>
                {PatientScreeningStatusOptions?.find(option => option?.value === selectedOption)?.label || _.upperFirst(selectedOption) || 'N/A'}
            </div>
        }

    </>
}

export default PhysicianAppointmentScreeningStatusComponent;


