import React, {useCallback, useState} from "react";
import TableFilterPaginationV2 from "../../components/generic/TableFilterPaginationV2";
import {useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import Select from "react-select";
import DateRangePickerComponent from "../../components/generic/date-range-picker/DateRangePickerComponent";

interface IPhysicianAppointmentsFiltersComponentProps {
    searchText: string
    setSearchText: (text: string) => void
    setCurrentPage: (page: number) => void
    onFilterChange: (startDate: string | undefined, endDate: string | undefined) => void;
    startDate?: string;
    endDate?: string;
    practiceId?: string;
    setPracticeId: (id: string) => void;
}

const PhysicianAppointmentsFiltersComponent = (props: IPhysicianAppointmentsFiltersComponentProps)=>{

    const {startDate, endDate, practiceId, setCurrentPage, setSearchText, searchText, setPracticeId, onFilterChange} = props;
    const [dateFilter, setDateFilter] = useState<{
        startDate?: string,
        endDate?: string
    }>({startDate: startDate, endDate: endDate});
    const {practiceMaster} = useSelector((state: StateParams) => state.careCoordinatorMasterData);

    const handleDateFilterChange = useCallback((startDate: string, endDate: string) => {
        setDateFilter({startDate, endDate});
        onFilterChange(startDate, endDate);
    }, [onFilterChange]);

    const renderFilters = useCallback(() => {
        return <div className='flex flex-row align-bottom gap-3'>
            <Select
                placeholder={"Practice"}
                className="min-w-[250px] max-w-[250px]"
                classNamePrefix={"sj-react-select"}
                options={practiceMaster}
                getOptionLabel={option => `${option?.name}`}
                getOptionValue={option => option?.id}
                value={practiceMaster.find(x => x.id === practiceId)}
                isClearable={true}
                onChange={x => {
                    if (x) {
                        if (!x?.id || x?.id === practiceId) return;
                        setPracticeId( x?.id);
                        setCurrentPage(1);
                    } else {
                        setPracticeId('');
                        setCurrentPage(1);
                    }
                }}
                defaultValue={undefined}
                isSearchable={practiceMaster.length > 5}
            />
            <DateRangePickerComponent
                startDate={dateFilter.startDate}
                endDate={dateFilter.endDate}
                onFilterChange={handleDateFilterChange}
                isClearable={true}
            />
            <TableFilterPaginationV2
                placeholder={"Search by Name"}
                setTableFilter={setSearchText}
                currentPageHandler={setCurrentPage}
            />
        </div>
    }, [setPracticeId, dateFilter, handleDateFilterChange, practiceId, setSearchText, setCurrentPage, practiceMaster]);

    return <div className='flex flex-row gap-2'>{renderFilters()}</div>
}

export default PhysicianAppointmentsFiltersComponent;
